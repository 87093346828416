import { property } from 'lit/decorators.js';
import { LitElement } from 'lit';
import { Constructor, LinkTarget } from '../../../libs/types';

export const LinkMixin = <T extends Constructor<LitElement>>(SuperClass: T) => {
  class LinkComponent extends SuperClass {
    @property({
      type: String,
    })
    href = '';

    @property({
      type: String,
    })
    target: LinkTarget = '_self';

    @property({
      type: Number,
    })
    tabIndex = 0;

    @property({
      attribute: 'download',
      type: Boolean,
    })
    canDownload = false;
  }

  return LinkComponent;
};
