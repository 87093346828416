import { css, html, LitElement, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { LinkMixin } from '../../../utils/mixins';
import { ifNotEmpty } from '../../../utils/directives';
import { baseReset } from '../../../../libs/styles/reset';

@customElement('cal-navigation-group-title')
export class CalNavigationGroupTitleComponent extends LinkMixin(LitElement) {
  protected render(): TemplateResult {
    return html`
      <a class="cal-navigation-group-title"
         href="${ifNotEmpty(this.href)}"
         .target=${this.target}
      >
        <slot></slot>
      </a>
    `;
  }

  static styles = [
    baseReset,
    css`
      :host {
      }
      
      .cal-navigation-group-title {
        text-decoration: none;
        color: var(--cal-navigation-group-title-color, #000000);
        font-size: var(--cal-navigation-group-title-font-size, 18px);
        font-weight: var(--cal-navigation-group-title-font-weight, bold);
        user-select: none;
        transition: color ease-out .2s;
        white-space: nowrap;
      }

      .cal-navigation-group-title:hover {
        color: var(--cal-navigation-group-title-hover-color, var(--cal-navigation-group-title-color, currentColor));
      }
    `,
  ];
}

declare global {
  interface HTMLElementTagNameMap {
    'cal-navigation-group-title': CalNavigationGroupTitleComponent;
  }
}
