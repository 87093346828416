import { css, html, LitElement, PropertyValues, TemplateResult } from 'lit';
import {
  customElement,
  property,
  queryAssignedElements,
} from 'lit/decorators.js';
import { baseReset } from '../../../libs/styles/reset';
import { VideoController } from './video-controller';
import { classMap } from 'lit/directives/class-map.js';

@customElement('cal-video-player')
export class CalVideoPlayerComponent extends LitElement {
  private videoController = new VideoController(this);

  @property({
    attribute: 'playing',
    type: Boolean,
    reflect: true,
  })
  isPlaying = false;

  @queryAssignedElements({ selector: 'video' })
  videoElements!: HTMLVideoElement[];
  video: HTMLVideoElement;

  protected firstUpdated(changedProperties: PropertyValues) {
    super.firstUpdated(changedProperties);

    if (this.videoElements?.length > 0) {
      this.video = this.videoElements[0];
      this.videoController.setup();
      this.requestUpdate();
    }
  }

  protected render(): TemplateResult {
    return html`
      <div class=${classMap({
        player: true,
        'player--has-controls': this.hasControls,
      })}>
        <div class="player__main">
          <slot></slot>
        </div>
        <div class="player__overlay">
          <button class="player__play-toggle"
                  @click=${this.togglePlay.bind(this)}
          >
            <svg viewBox="0 0 60 60"
                 fill="none"
                 class="play-icon"
            >
              <circle cx="30" cy="30" r="28.5" stroke-width="3"
                      class="play-icon__circle"
              />
              <path d="M41.25 30L24.0625 43.5316L24.0625 16.4684L41.25 30Z" 
                    class="play-icon__triangle"
              />
            </svg>
          </button>
        </div>
      </div>
    `;
  }

  togglePlay() {
    this.videoController.togglePlay();
  }

  play() {
    this.videoController.play();
  }

  pause() {
    this.videoController.pause();
  }

  get hasControls(): boolean {
    return this.video?.controls;
  }

  static styles = [
    baseReset,
    css`
      :host {
        display: block;
        width: 100%;
        height: 100%;
      }
      
      .player {
        position: relative;
        width: 100%;
        height: 100%;
      }
      
      .player__main {
        width: 100%;
        height: 100%;
      }
      
      ::slotted(video) {
        width: 100%;
        height: 100%;
      }
      
      :host([cover]) ::slotted(video) {
        object-fit: cover;
      }
      
      .player__overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
      }
      
      .player__play-toggle {
        position: absolute;
        pointer-events: all;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        border: 0;
        margin: auto;
        background: transparent;
      }
      
      .player--has-controls .player__play-toggle {
        display: none;
        pointer-events: none;
      }
      
      :host([playing]) .player__play-toggle {
        opacity: 0; 
      }
      
      .play-icon {
        width: 16%;
        max-width: 96px;
        max-height: 96px;
      }
      
      .play-icon__circle {
        stroke: var(--cal-video-player-icon-color, var(--color-grey--warm, #ffffff));
      }
      
      .play-icon__triangle {
        fill: var(--cal-video-player-icon-color, var(--color-grey--warm, #ffffff));
      }
    `,
  ];
}

declare global {
  interface HTMLElementTagNameMap {
    'cal-video-player': CalVideoPlayerComponent;
  }
}
