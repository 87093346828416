import { css, html, LitElement, PropertyValues, TemplateResult } from 'lit';
import { customElement, queryAssignedElements } from 'lit/decorators.js';
import { baseReset } from '../../../../libs/styles/reset';
import { MediaQuery, ResponsiveTargets } from '../../../styles/responsive';
import {
  CalNavigationGroupComponent,
  CalNavigationGroupsAccordionController,
  CalNavigationGroupsContainer,
} from '../navigation-group';

@customElement('cal-footer')
export class CalFooterComponent
  extends LitElement
  implements CalNavigationGroupsContainer
{
  private navigationGroupsAccordionController: CalNavigationGroupsAccordionController;

  @queryAssignedElements({ selector: 'cal-navigation-group' })
  navigationGroups!: CalNavigationGroupComponent[];

  protected firstUpdated(changedProperties: PropertyValues<this>) {
    super.firstUpdated(changedProperties);
    this.navigationGroupsAccordionController =
      new CalNavigationGroupsAccordionController(this);
  }

  protected render(): TemplateResult {
    return html`
      <nav class="cal-footer">
        <div class="cal-footer__inner">
          <div class="cal-footer__top">
            <div class="cal-footer__navigation-groups">
              <slot></slot>
            </div>
          </div>

          <div class="cal-footer__bottom">
            <div class="cal-footer__bottom-left">
              <div class="cal-footer__language-switcher">
                <slot name="language-switcher"></slot>
              </div>
            </div>

            <div class="cal-footer__bottom-right">
              <div class="cal-footer__legal-links">
                <slot name="legal-links"></slot>
              </div>

              <div class="cal-footer__social-media">
                <slot name="social-media"></slot>
              </div>
            </div>
          </div>
        </div>
      </nav>
    `;
  }

  static styles = [
    baseReset,
    css`
      :host {
        display: block;
        width: 100%;

        --cal-language-link-color: var(--color-white);
        --cal-language-link-hover-color: var(--color-black);
        --cal-language-link-active-color: var(--color-black);
        --cal-language-link-line-to-text-spacing: 0;
        --cal-language-link-active-line-thickness: 0;

        --cal-navigation-group-title-color: var(--color-white);
        --cal-navigation-group-title-hover-color: var(--color-black);
        --cal-navigation-group-arrow-color: var(--color-white);
        --cal-navigation-item-color: var(--color-white);
        --cal-navigation-item-hover-color: var(--color-black);
      }

      .cal-footer {
        background-color: var(--color-grey--warm);
        width: 100%;
      }

      .cal-footer__inner {
        max-width: var(--content-max-width);
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 32px;
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 74px;
      }

      .cal-footer__top {
        margin-bottom: 32px;
      }

      .cal-footer__navigation-groups {
        list-style: none;
      }

      ::slotted(cal-navigation-group) {
        margin-bottom: 19px;
      }

      ::slotted(cal-navigation-group:last-child) {
        margin-bottom: 0;
      }

      .cal-footer__bottom {

      }

      .cal-footer__language-switcher {
        margin-bottom: 32px;
      }

      .cal-footer__legal-links {
        margin-bottom: 60px;
        padding-bottom: 16px;
        border-bottom: 1px solid var(--color-white);
      }
    `,
    MediaQuery.range(
      ResponsiveTargets.lg,
      ResponsiveTargets.xl
    )(css`
      :host {
        --cal-navigation-group-title-items-spacing: 42px;
        --cal-navigation-group-title-font-size: 14px;
        --cal-navigation-item-font-size: 14px;

        --cal-language-switcher-item-spacing: 16px;
        --cal-language-link-font-size: 15px;
        --cal-language-link-padding-right: 0;

        --cal-legal-links-spacing: 24px;
        --cal-legal-link-font-weight: var(--font-weight-bold);
        --cal-legal-link-font-size: 15px;
      }

      .cal-footer__inner {
        padding-top: 44px;
        padding-left: 56px;
        padding-right: 56px;
        padding-bottom: 48px;
      }

      .cal-footer__top {
        margin-bottom: 64px;
      }

      .cal-footer__navigation-groups {
        display: flex;
        justify-content: space-between;
      }

      .cal-footer__bottom {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }
      
      .cal-footer__bottom-left {
        
      }

      .cal-footer__language-switcher {
        margin-bottom: 0;
      }

      .cal-footer__bottom-right{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
      
      .cal-footer__legal-links {
        order: 1;
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: 0;
      }

      .cal-footer__social-media {
        margin-bottom: 32px;
      }
    `),
  ];
}

declare global {
  interface HTMLElementTagNameMap {
    'cal-footer': CalFooterComponent;
  }
}
