import { css, html, LitElement, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { baseReset } from '../../../libs/styles/reset';

@customElement('cal-stat')
export class CalStatComponent extends LitElement {
  protected render(): TemplateResult {
    return html`
      <div class="stat">
        <slot></slot>
      </div>
    `;
  }

  static styles = [
    baseReset,
    css`
      :host {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }
      
      ::slotted(cal-stat-value) {
        color: var(--cal-stat-primary-color, #000000);
        margin-bottom: var(--cal-stat-value-margin-bottom, 10px);
      }
      
      ::slotted(cal-stat-unit) {
        color: var(--cal-stat-primary-color, #000000);
      }

      ::slotted(cal-stat-description) {
        color: var(--cal-stat-secondary-color, #000000);
      }
    `,
  ];
}

declare global {
  interface HTMLElementTagNameMap {
    'cal-stat': CalStatComponent;
  }
}
