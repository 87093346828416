import { css, html, LitElement, PropertyValues, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { baseReset } from '../../../../libs/styles/reset';
import { ServiceLocator } from '../../../../libs/services';
import { Service } from '../../../services';
import { OverlayService } from './overlay-service';
import { Subscription } from 'rxjs';
import { CalOverlayComponent } from './cal-overlay';
import { ScrollBlockingService } from '../../../utils/services/scroll-blocking-service';

@customElement('cal-overlay-outlet')
export class CalOverlayOutletComponent extends LitElement {
  private subscriptions: Subscription[] = [];
  private overlayService: OverlayService;
  private scrollBlockingService: ScrollBlockingService;

  currentOverlay: CalOverlayComponent;

  @property({
    attribute: 'open',
    reflect: true,
    type: Boolean,
  })
  isOpen = false;

  connectedCallback() {
    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  protected firstUpdated(changedProperties: PropertyValues<this>) {
    super.firstUpdated(changedProperties);
    this.getServices();
  }

  private getServices() {
    setTimeout(() => {
      this.overlayService = ServiceLocator.get(Service.Overlay);
      this.scrollBlockingService = ServiceLocator.get(Service.ScrollBlocking);

      if (!this.overlayService) {
        this.getServices();
      } else {
        this.onServicesFound();
      }
    }, 0);
  }

  private onServicesFound() {
    const overlaySubscription = this.overlayService.currentOverlay.subscribe(
      (overlay) => {
        if (overlay) {
          this.currentOverlay = overlay;
          this.isOpen = true;
          this.scrollBlockingService.block();
          this.requestUpdate();
        } else {
          this.isOpen = false;
          this.scrollBlockingService.unblock();

          // wait before animation done before removing current overlay
          setTimeout(() => {
            this.currentOverlay = null;
            this.requestUpdate();
          }, 300);
        }
      }
    );
    this.subscriptions.push(overlaySubscription);
  }

  close(event: MouseEvent) {
    const target = event.target as HTMLElement;

    if (!target?.closest('.overlay-outlet__overlay')) {
      this.overlayService.close();
    }
  }

  protected render(): TemplateResult {
    return html`
      <div class="overlay-outlet">
        <div class="overlay-outlet__backdrop"></div>

        <div class="overlay-outlet__foreground"
             @click="${this.close.bind(this)}"
        >
          <div class="overlay-outlet__inner">
            <div class="overlay-outlet__overlay">
              ${this.currentOverlay}
            </div>
          </div>
        </div>
      </div>
    `;
  }

  static styles = [
    baseReset,
    css`
      :host {
        position: fixed;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: var(--overlay-z-index);
        pointer-events: none;
      }

      :host([open]) {
        pointer-events: all;
      }

      .overlay-outlet {
        position: relative;
        width: 100%;
        height: 100%;
      }

      .overlay-outlet__backdrop {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        opacity: 0;
        transition: opacity ease-out .3s;
        background-color: rgba(0, 0, 0, 0.6);
      }

      :host([open]) .overlay-outlet__backdrop {
        opacity: 1;
      }

      .overlay-outlet__foreground {
        position: relative;
        width: 100%;
        height: 100%;
        max-height: 100%;
        z-index: 10;
        overflow-x: hidden;
        overflow-y: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateY(100vh);
        transition: transform ease-out .3s;
      }

      :host([open]) .overlay-outlet__foreground {
        transform: translateY(0);
      }

      .overlay-outlet__inner {
        padding-top: 96px;
        padding-bottom: 96px;
        max-width: 880px;
        width: 100%;
      }

      .overlay-outlet__overlay {
      }
    `,
  ];
}

declare global {
  interface HTMLElementTagNameMap {
    'cal-overlay-outlet': CalOverlayOutletComponent;
  }
}
