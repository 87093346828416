import { css, html, LitElement, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { LinkMixin } from '../../../utils/mixins';
import { baseReset } from '../../../../libs/styles/reset';

@customElement('cal-navigation-item')
export class CalNavigationItemComponent extends LinkMixin(LitElement) {
  private onClick = (event: MouseEvent) => {
    const wasNotPrevented = this.dispatchEvent(
      new CustomEvent<MouseEvent>('onNavigationItemClick', {
        bubbles: true,
        cancelable: true,
        detail: event,
      })
    );

    if (!wasNotPrevented) {
      event.preventDefault();
    }
  };

  protected render(): TemplateResult {
    return html`
      <a class="cal-navigation-item"
      .href="${this.href}"
      .target="${this.target || '_self'}"
         .tabIndex="${this.tabIndex}"
         @click="${this.onClick}"
      >
        <slot></slot>
      </a>
    `;
  }

  static styles = [
    baseReset,
    css`
      :host {
        display: block;
      }

      .cal-navigation-item {
        font-size: var(--cal-navigation-item-font-size, 16px);
        color: var(--cal-navigation-item-color, #000000);
        text-decoration: none;
        user-select: none;
        transition: color ease-out .2s;
      }
      
      .cal-navigation-item:hover {
        color: var(--cal-navigation-item-hover-color, var(--cal-navigation-item-color, currentColor));
      }
    `,
  ];
}

declare global {
  interface HTMLElementTagNameMap {
    'cal-navigation-item': CalNavigationItemComponent;
  }
}
