import { css, html, LitElement, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { LinkMixin } from '../../../utils/mixins';
import { baseReset } from '../../../../libs/styles/reset';
import { LinkTarget } from '../../../../libs/types';

@customElement('cal-social-media-link')
export class CalSocialMediaLinkComponent extends LinkMixin(LitElement) {
  @property({
    reflect: true,
    type: String,
  })
  target: LinkTarget = '_blank';

  protected render(): TemplateResult {
    return html`
      <a .href=${this.href}
         .target=${this.target}
         class="cal-social-media-link"
      >
        <span class="social-media-link__icon">
          <slot name="icon"></slot>
        </span>
        <span class="social-media-link__text">
          <slot></slot>
        </span>
      </a>
    `;
  }

  static styles = [
    baseReset,
    css`
      .cal-social-media-link {
        text-decoration: none;
        color: var(--color-white);
        transition: color ease-out .2s;
      }
      
      .cal-social-media-link:hover {
        color: var(--color-black);
      }
      
      .social-media-link__icon {
        position: relative;
        width: 24px;
        height: 24px;
        display: block;
        user-select: none;
      }
      
      ::slotted(cal-icon) {
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 60px;
        transform: translate(-50%, -50%);
      }
      
      .social-media-link__text {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
        user-select: none;
      }
    `,
  ];
}

declare global {
  interface HTMLElementTagNameMap {
    'cal-social-media-link': CalSocialMediaLinkComponent;
  }
}
