import { css, html, LitElement, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { baseReset } from '../../../../libs/styles/reset';

@customElement('cal-language-switcher')
export class CalLanguageSwitcherComponent extends LitElement {
  protected render(): TemplateResult {
    return html`
      <div class="cal-language-switcher">
        <slot></slot>
      </div>
    `;
  }

  static styles = [
    baseReset,
    css`
      .cal-language-switcher {
        display: flex;
      }
      
      ::slotted(*) {
        list-style: none;
        margin-right: var(--cal-language-switcher-item-spacing, 4px);
      }
    `,
  ];
}

declare global {
  interface HTMLElementTagNameMap {
    'cal-language-switcher': CalLanguageSwitcherComponent;
  }
}
