import { css, html, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { HeroSlide } from '../hero-slide';
import { MediaQuery, ResponsiveTargets } from '../../../styles/responsive';

export type CalTextSlideImageAlign = 'let' | 'center' | 'right';

@customElement('cal-text-slide')
export class CalTextSlideComponent extends HeroSlide {
  @property({
    attribute: 'mobile-image-url',
    type: String,
  })
  mobileImageUrl = '';

  @property({
    attribute: 'desktop-image-url',
    type: String,
  })
  desktopImageUrl = '';

  @property({
    attribute: 'background-align',
    type: String,
    reflect: true,
  })
  backgroundAlign: CalTextSlideImageAlign = 'center';

  protected render(): TemplateResult {
    return html`
      <div class="slide">
        <div class="slide__desktop-image"
             style="background-image: url('${this.desktopImageUrl}')"
        ></div>
        <div class="slide__mobile-image"
             style="background-image: url('${this.mobileImageUrl}')"
        ></div>
        <div class="slide__inner">
          <div class="slide__contents">
            <slot></slot>
          </div>
        </div>
      </div>
    `;
  }

  static styles = [
    ...super.slideStyles,
    css`
      .slide {
        width: 100%;
        height: 100%;
        overflow: hidden;
      }

      .slide__inner {
        width: 100%;
        height: 100%;
        max-width: var(--content-max-width);
        margin-left: auto;
        margin-right: auto;
      }

      .slide__mobile-image {
        position: relative;
        background-position: var(--cal-text-slide-mobile-focus-x, center) var(--cal-text-slide-mobile-focus-y, center);
        background-size: cover;
        z-index: 0;
        padding-top: 62.5%;
      }
      
      .slide__desktop-image {
        display: none;
      }

      .slide__contents {
        z-index: 10;
        padding-top: 30px;
        padding-left: var(--content-side-padding-default);
        padding-right: var(--content-side-padding-default);
        padding-bottom: 72px;
        background-color: var(--cal-text-slide-text-backgrond-color, var(--color-grey--warm-light, #FFFFFF));
      }
    `,

    MediaQuery.range(
      ResponsiveTargets.lg,
      ResponsiveTargets.xl
    )(css`
      .slide__mobile-image {
        display: none;
      }
      
      .slide__desktop-image {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        background-position: var(--cal-text-slide-desktop-focus-x, center) var(--cal-text-slide-desktop-focus-y, center);
        background-size: auto 100%;
        background-repeat: no-repeat;
        padding-top: 0;
      }
      
      .slide__inner {
        position: relative;
        z-index: 5;
        display: flex;
        align-items: var(--cal-text-slide-align, center);
        justify-content: var(--cal-text-slide-justify, flex-start);
        padding-top: var(--cal-text-slide-padding-top, 0px);
        padding-bottom: var(--cal-text-slide-padding-bottom, 0px);
      }
      
      .slide__contents {
        padding-top: 0;
        padding-bottom: 0;
        background-color: transparent;
        max-width: var(--cal-text-slide-width, 740px);
        width: 100%;
      }
    `),

    css`
      @media screen and (min-width: 2054px) {
        .slide__desktop-image {
          background-position: center;
        }
        
        :host([background-align="left"]) .slide__desktop-image {
          background-position: left center;
        }

        :host([background-align="right"]) .slide__desktop-image {
          background-position: right center;
        }
      }
    `,
  ];
}

declare global {
  interface HTMLElementTagNameMap {
    'cal-text-slide': CalTextSlideComponent;
  }
}
