import { ViewController } from '../../../libs/view-controllers/view-controller';
import { HorizontalScrollbarGlue } from '../../components/horizontal-scrollbar';
import { invLerp } from '../../../libs/math';

export class TimelineController extends ViewController {
  private scrollbarGlue: HorizontalScrollbarGlue;
  private scrollContainer: HTMLElement;

  private scrollProgress = 0;
  private scrollDirection: 'unknown' | 'up' | 'down' = 'unknown';

  onInit() {
    this.scrollbarGlue = new HorizontalScrollbarGlue(this);

    // this.getElements();
    // this.registerListeners();
  }

  private getElements() {
    this.scrollContainer = this.host.querySelector(
      '.timeline__scroll-container'
    );
  }

  private registerListeners() {
    this.scrollContainer?.addEventListener('wheel', (event: WheelEvent) => {
      this.updateScrollProgress();

      // cancel if scrolled horizontally
      {
        const scrolledHorizontally =
          Math.abs(event.deltaX) > Math.abs(event.deltaY);
        if (scrolledHorizontally) {
          return;
        }
      }

      // if scrollContainer not entirely visible
      {
        const clientRect = this.scrollContainer.getBoundingClientRect();

        if (clientRect.top < 0 || clientRect.bottom > window.innerHeight) {
          return;
        }
      }

      // update scrollLeft depending on scroll direction (up/down)
      {
        this.scrollDirection = event.deltaY < 0 ? 'up' : 'down';

        const offset = event.deltaY;

        if (this.scrollProgress < 1 && this.scrollDirection === 'down') {
          event.preventDefault();
        } else if (this.scrollProgress > 0 && this.scrollDirection === 'up') {
          event.preventDefault();
        }

        this.scrollContainer.scrollLeft += offset;
      }
    });
  }

  private updateScrollProgress() {
    const scrollLeft = this.scrollContainer.scrollLeft;
    const maxScrollLeft =
      this.scrollContainer.scrollWidth - this.scrollContainer.clientWidth;

    this.scrollProgress = invLerp(0, scrollLeft, maxScrollLeft);
  }
}

TimelineController.attachTo('.timeline');
