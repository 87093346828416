export function conditionalCSSClass(
  host: HTMLElement,
  cssClass: string,
  condition: boolean
) {
  if (condition) {
    host.classList.add(cssClass);
  } else {
    host.classList.remove(cssClass);
  }
}
