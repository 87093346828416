import { css, html, LitElement, PropertyValues, TemplateResult } from 'lit';
import {
  customElement,
  property,
  query,
  queryAssignedElements,
} from 'lit/decorators.js';
import { baseReset } from '../../../../libs/styles/reset';
import { CalNavigationGroupExpandableController } from './cal-navigation-group-expandable-controller';
import { CalNavigationItemComponent } from './cal-navigation-item';

@customElement('cal-navigation-group')
export class CalNavigationGroupComponent extends LitElement {
  private expandableController = new CalNavigationGroupExpandableController(
    this
  );

  @property({
    attribute: 'expanded',
    type: Boolean,
    reflect: true,
  })
  isExpanded = false;

  @property({
    attribute: 'expandable',
    type: Boolean,
    reflect: true,
  })
  isExpandable = false;

  @query('.cal-navigation-group__expandable')
  expandableContainer!: HTMLElement;

  @query('.cal-navigation-group__items')
  itemsContainer!: HTMLElement;

  @queryAssignedElements({ selector: 'cal-navigation-item' })
  navigationItems!: CalNavigationItemComponent[];

  protected firstUpdated(changedProperties: PropertyValues<this>) {
    super.firstUpdated(changedProperties);

    if (this.isExpanded) {
      this.expandableController.expand();
    }
  }

  protected update(changedProperties: PropertyValues<this>) {
    super.update(changedProperties);

    if (
      changedProperties.has('isExpandable') ||
      changedProperties.has('isExpanded')
    ) {
      for (const navigationItem of this.navigationItems) {
        if (this.isExpandable && !this.isExpanded) {
          navigationItem.tabIndex = -1;
        } else {
          navigationItem.tabIndex = 0;
        }
      }
    }
  }

  protected render(): TemplateResult {
    return html`
      <div class="cal-navigation-group">
        <button class="cal-navigation-group__header"
                @click=${this.onHeaderClick.bind(this)}
        >
          <div class="cal-navigation-group__title">
            <slot name="title"></slot>
          </div>

          <div class="cal-navigation-group__arrow">
            <svg viewBox="0 0 12 18"
                 height="16"
                 xmlns="http://www.w3.org/2000/svg"
                 class="cal-navigation-group__svg"
            >
              <path
                  d="M2.111,17.731L11.466,9L2.111,0.269L0.746,1.731L8.534,9C8.534,9 0.746,16.269 0.746,16.269L2.111,17.731Z"/>
            </svg>
          </div>
        </button>

        <div class="cal-navigation-group__expandable">
          <div class="cal-navigation-group__items">
            <slot></slot>

            <div class="cal-navigation-group__end-line"></div>
          </div>
        </div>
      </div>
    `;
  }

  private onHeaderClick() {
    if (this.isExpandable) {
      this.expandableController.expand();
    }
  }

  expand() {
    this.expandableController.expand();
  }

  collapse() {
    this.expandableController.collapse();
  }

  static styles = [
    baseReset,
    css`
      :host {
        display: block;
      }

      .cal-navigation-group {

      }

      .cal-navigation-group__header {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: var(--cal-navigation-group-title-items-spacing, 19px);
        background: transparent;
        width: 100%;
        border: 0;
      }

      /* NOTE: This increases clickable area */
      :host([expandable]) .cal-navigation-group__header::before {
        content: '';
        display: block;
        position: absolute;
        top: -19px;
        left: 0;
        width: 100%;
        height: calc(100% + 19px);
        cursor: pointer;
      }
      
      :host([expanded]) .cal-navigation-group__header::before {
        display: none;
      }

      .cal-navigation-group__title {

      }

      .cal-navigation-group__arrow {
        display: flex;
        flex-shrink: 0;
        margin-left: 16px;
      }

      .cal-navigation-group__svg {
        fill: var(--cal-navigation-group-arrow-color, #000000);
        transition: transform ease-out .2s, fill ease-out .2s;
        display: none;
      }

      :host([expandable]) .cal-navigation-group__svg {
        display: block;
      }

      :host([expanded]) .cal-navigation-group__svg {
        transform: rotate(90deg);
      }

      .cal-navigation-group__expandable {
      }

      :host([expandable]) .cal-navigation-group__expandable {
        height: 0;
        overflow: hidden;
        border-bottom: 1px solid #ffffff;
        transition: height ease-out .2s;
      }

      :host([expanded]) .cal-navigation-group__expandable {
        height: auto;
      }

      .cal-navigation-group__items {
        position: relative;
        list-style: none;
        padding-bottom: var(--cal-navigation-group-item-spacing, 19px);
      }

      ::slotted(cal-navigation-item) {
        margin-bottom: var(--cal-navigation-group-item-spacing, 19px);
      }

      ::slotted(cal-navigation-item:last-child) {
        margin-bottom: 0;
      }

      .cal-navigation-group__end-line {
        position: absolute;
        top: 100%;
        left: 0;
        border-top: 2px solid var(--cal-navigation-group-bottom-line-color, #000000);
        width: var(--cal-navigation-group-bottom-line-width, 0px);
      }
    `,
  ];
}

declare global {
  interface HTMLElementTagNameMap {
    'cal-navigation-group': CalNavigationGroupComponent;
  }
}
