import { ReactiveController } from 'lit';
import { CalHeroSliderComponent } from './cal-hero-slider';
import { clamp } from '../../../libs/math';
import { HeroSlide } from './hero-slide';
import { ResponsiveController } from '../../utils/controllers';
import { Timer, TimerEvent } from '../../../libs/time';
import { SwipeController } from './swipe-controller';

export class HeroSliderController implements ReactiveController {
  private responsiveController: ResponsiveController;
  private swipeController: SwipeController;
  private host: CalHeroSliderComponent;

  private timer: Timer;

  private _currentSlideIndex = 0;
  private _currentSlide: HeroSlide;

  constructor(host: CalHeroSliderComponent) {
    this.host = host;
    this.host.addController(this);

    this.responsiveController = new ResponsiveController(this.host);
    this.swipeController = new SwipeController(this.host);
  }

  hostConnected(): void {
    this.setupTimer();
    this.setupSwipeGesture();
  }

  private setupTimer() {
    // reasons to pause and reset:
    // - not in viewport
    // - manual slide change
    // - video is playing

    this.timer = new Timer(this.host.autoplayDuration * 1000);

    if (this.host.shouldAutoplay) {
      this.timer.events.on(TimerEvent.Done, () => {
        this.goToNext();
        this.timer.reset();
        this.timer.play();
      });

      this.timer.play();
    }
  }

  private setupSwipeGesture() {
    this.swipeController.events.on('swipeleft', () => {
      this.goToNext();
    });

    this.swipeController.events.on('swiperight', () => {
      this.goToPrevious();
    });
  }

  hostDisconnected(): void {}

  hostUpdate(): void {}

  hostUpdated(): void {}

  pauseTimer() {
    this.timer.pause();
  }

  stopTimer() {
    this.timer.pause();
    this.timer.reset();
  }

  restartTimer() {
    this.timer.pause();
    this.timer.reset();
    this.timer.play();
  }

  goToNext() {
    const index = this._currentSlideIndex + 1;
    if (index > this.maxIndex) {
      this.goToSlide(0);
    } else {
      this.goToSlide(index);
    }
  }

  goToPrevious() {
    const index = this._currentSlideIndex - 1;
    if (index < 0) {
      this.goToSlide(this.maxIndex);
    } else {
      this.goToSlide(index);
    }
  }

  goToSlide(index: number) {
    const previousSlide = this._currentSlide;
    if (previousSlide) {
      previousSlide.isActive = false;
      previousSlide.onDeactivate();
    }

    this._currentSlideIndex = clamp(0, index, this.maxIndex);

    this._currentSlide = this.slides[this._currentSlideIndex];
    this._currentSlide.isActive = true;
    this._currentSlide.onActivate();

    this.host.requestUpdate();
  }

  get currentSlideIndex(): number {
    return this._currentSlideIndex;
  }

  get currentSlide(): HeroSlide {
    return this._currentSlide;
  }

  get maxIndex(): number {
    return this.slides.length - 1;
  }

  get slides(): HeroSlide[] {
    return this.host.slides;
  }
}
