import { css, html, LitElement, PropertyValues, TemplateResult } from 'lit';
import { customElement, queryAssignedElements } from 'lit/decorators.js';
import { baseReset } from '../../../../libs/styles/reset';
import {
  CalNavigationGroupComponent,
  CalNavigationGroupsAccordionController,
  CalNavigationGroupsContainer,
} from '../navigation-group';
import { MediaQuery, ResponsiveTargets } from '../../../styles/responsive';

@customElement('cal-navigation-overlay')
export class CalNavigationOverlayComponent
  extends LitElement
  implements CalNavigationGroupsContainer
{
  private navigationGroupsAccordionController: CalNavigationGroupsAccordionController;

  @queryAssignedElements({ selector: 'cal-navigation-group' })
  navigationGroups!: CalNavigationGroupComponent[];

  protected firstUpdated(changedProperties: PropertyValues<this>) {
    super.firstUpdated(changedProperties);

    this.navigationGroupsAccordionController =
      new CalNavigationGroupsAccordionController(this);
  }

  protected render(): TemplateResult {
    return html`
      <div class="cal-navigation-overlay">
        <div class="cal-navigation-overlay__inner">
          <div class="cal-navigation-overlay__top">
            <div class="cal-navigation-overlay__grid">
              <slot></slot>
            </div>
          </div>

          <div class="cal-navigation-overlay__bottom">
            <div class="cal-navigation-overlay__language-switcher">
              <slot name="language-switcher"></slot>
            </div>
          </div>
        </div>
      </div>
    `;
  }

  static styles = [
    baseReset,
    css`
      :host {
        --cal-navigation-group-title-color: var(--color-red);
        --cal-navigation-group-arrow-color: var(--color-red);
        --cal-navigation-item-color: var(--color-grey--warm);

        --cal-language-link-color: var(--color-white);
        --cal-language-link-hover-color: var(--color-grey--warm);
        --cal-language-link-active-color: var(--color-grey--warm);
      }

      .cal-navigation-overlay {
        position: relative;
        width: 100%;
        height: 100%;
        background-color: var(--color-black);
        overflow: auto;
      }

      .cal-navigation-overlay__inner {
        padding-top: 120px;
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 108px;
        max-width: var(--content-max-width);
        margin-left: auto;
        margin-right: auto;
      }

      ::slotted(cal-navigation-group) {
        margin-bottom: 19px;
      }

      ::slotted(cal-navigation-group[expanded]) {
        --cal-navigation-group-title-color: var(--color-white);
        --cal-navigation-group-arrow-color: var(--color-white);
      }
      
      .cal-navigation-overlay__language-switcher {
        margin-top: 30px;
      }
    `,
    MediaQuery.range(
      ResponsiveTargets.lg,
      ResponsiveTargets.xl
    )(css`
      :host {
        --cal-navigation-group-title-hover-color: var(--color-white);
        --cal-navigation-group-title-font-size: 24px;
        --cal-navigation-group-title-items-spacing: 32px;
        --cal-navigation-group-item-spacing: 32px;
        --cal-navigation-group-bottom-line-color: var(--color-red);
        --cal-navigation-group-bottom-line-width: 48px;
        
        --cal-navigation-item-color: var(--color-white);
        --cal-navigation-item-hover-color: var(--color-grey--warm);
        --cal-navigation-item-font-size: 18px;
        
        --cal-language-switcher-item-spacing: 30px;
        --cal-language-link-line-to-text-spacing: 12px;
        --cal-language-link-padding-right: 16px;
      }
      
      .cal-navigation-overlay {
      }

      .cal-navigation-overlay__inner {
        position: relative;
        height: 100%;
        padding-top: 100px;
        padding-left: 56px;
        padding-right: 56px;
        padding-bottom: 40px;
        display: flex;
        flex-direction: column;
      }

      .cal-navigation-overlay__top {
        flex-grow: 1;
      }

      .cal-navigation-overlay__grid {
        display: block;
        column-count: 4;
        column-rule: 0.5px solid var(--color-grey);
      }

      ::slotted(cal-navigation-group) {
        display: block;
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 2px; /* for red line under group */
        margin-bottom: 40px;
        break-inside: avoid;
        height: 100%;
      }
      
      ::slotted(cal-navigation-group:nth-child(1)) {
        break-after: column;
        padding-left: 0;
        border-left: 0;
      }
      
      ::slotted(cal-navigation-group:nth-child(3)) {
        break-after: column;
      }

      ::slotted(cal-navigation-group:nth-child(5)) {
        break-after: column;
      }

      ::slotted(cal-navigation-group[active]) {
        --cal-navigation-group-title-color: var(--color-white);
        --cal-navigation-group-arrow-color: var(--color-white);
        --cal-navigation-group-bottom-line-color: var(--color-white);
        --cal-navigation-item-color: var(--color-grey--warm);
      }

      .cal-navigation-overlay__bottom {
        position: relative;
        width: 100%;
      }

      .cal-navigation-overlay__language-switcher {
        position: absolute;
        left: 0;
        bottom: 0;
      }
    `),
    css`
      @media screen and (min-height: 1024px) and (min-width: 1280px) {
        .cal-navigation-overlay__inner {
          padding-top: 178px;
        }

        ::slotted(cal-navigation-group) {
          margin-bottom: 80px;
        }
      }
    `,
  ];
}

declare global {
  interface HTMLElementTagNameMap {
    'cal-navigation-overlay': CalNavigationOverlayComponent;
  }
}
