import { css, html, LitElement, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { LinkMixin } from '../../../utils/mixins';
import { baseReset } from '../../../../libs/styles/reset';
import { MediaQuery, ResponsiveTargets } from '../../../styles/responsive';

@customElement('cal-legal-link')
export class CalLegalLinkComponent extends LinkMixin(LitElement) {
  protected render(): TemplateResult {
    return html`
      <a .href=${this.href} 
         .target=${this.target}
         class="cal-legal-link"
      >
        <slot></slot>
      </a>
    `;
  }

  static styles = [
    baseReset,
    css`
      .cal-legal-link {
        display: block;
        margin-bottom: 0.5em;
        color: var(--cal-legal-link-color, #ffffff);
        text-decoration: none;
        font-size: var(--cal-legal-link-font-size, 16px);
        user-select: none;
        transition: color ease-out .2s;
      }
      
      .cal-legal-link:hover {
        color: var(--cal-legal-link-hover-color, #000000);
      }
    `,
    MediaQuery.range(
      ResponsiveTargets.lg,
      ResponsiveTargets.xl
    )(css`
      .cal-legal-link {
        margin-bottom: 0;
      }
    `),
  ];
}

declare global {
  interface HTMLElementTagNameMap {
    'cal-legal-link': CalLegalLinkComponent;
  }
}
