import { css, html, LitElement, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';

@customElement('cal-stat-description')
export class CalStatDescriptionComponent extends LitElement {
  protected render(): TemplateResult {
    return html`
      <slot></slot>
    `;
  }

  static styles = [
    css`
      :host {
        display: block;
        font-size: var(--cal-stat-description-font-size, 16px);
        line-height: 1.3125;
      }
    `,
  ];
}

declare global {
  interface HTMLElementTagNameMap {
    'cal-stat-description': CalStatDescriptionComponent;
  }
}
