import { css, html, LitElement, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { baseReset } from '../../../../libs/styles/reset';
import { MediaQuery, ResponsiveTargets } from '../../../styles/responsive';

@customElement('cal-legal-links')
export class CalLegalLinksComponent extends LitElement {
  protected render(): TemplateResult {
    return html`
      <div class="cal-legal-links">
        <slot></slot>
      </div>
    `;
  }

  static styles = [
    baseReset,
    css`
      .cal-legal-links {
        display: flex;
        flex-direction: column;
      }
      
      ::slotted(*) {
        list-style: none;
        margin-right: var(--cal-legal-links-spacing, 16px);
      }
      
      ::slotted(*:last-child) {
        margin-right: 0;
      }
    `,

    MediaQuery.range(
      ResponsiveTargets.lg,
      ResponsiveTargets.xl
    )(css`
      .cal-legal-links {
        flex-direction: row;
      }
    `),
  ];
}

declare global {
  interface HTMLElementTagNameMap {
    'cal-legal-links': CalLegalLinksComponent;
  }
}
