import { ViewController } from '../../../libs/view-controllers/view-controller';
import { StaggeredFadeInAnimation } from '../../animations';

export class BrandHighlightController extends ViewController {
  private itemsToAnimate: HTMLElement[] = [];
  private staggeredFadeInAnimation = new StaggeredFadeInAnimation();

  onInit() {
    this.getElements();
    this.setupAnimation();
  }

  private getElements() {
    const topLeft = this.getItem('.brand-highlight__top-left');
    const topRight = this.getItem('.brand-highlight__top-right');
    const bottomRight = this.getItem('.brand-highlight__bottom-right');
    const bottomLeft = this.getItem('.brand-highlight__bottom-left');

    this.itemsToAnimate.push(topLeft, topRight, bottomRight, bottomLeft);
  }

  private getItem(cssClass: string): HTMLElement {
    return this.host.querySelector<HTMLElement>(cssClass);
  }

  private setupAnimation() {
    this.staggeredFadeInAnimation.setItems(this.itemsToAnimate).setup();
  }
}

BrandHighlightController.attachTo('.brand-highlight');
