import { ReactiveController } from 'lit';
import { CalParallaxImageComponent } from './cal-parallax-image';
import { RenderLoop, RenderLoopEvent } from '../../../libs/rendering';
import { clamp, lerp, remap } from '../../../libs/math';

export class ParallaxController implements ReactiveController {
  private host: CalParallaxImageComponent;

  private renderLoop: RenderLoop;
  private frameRate = 60;

  private previousContainerCenterY = -1;
  private currentContainerCenterY = 0;

  private previousY = 0;
  private currentY = 0;
  private targetY = 0;

  private _isActive = true;
  set isActive(isActive: boolean) {
    this._isActive = isActive;

    // render once more when deactivated to remove transform
    if (!this._isActive) {
      this.render();
    }
  }
  get isActive(): boolean {
    return this._isActive;
  }

  constructor(host: CalParallaxImageComponent) {
    this.host = host;
    this.host.addController(this);
  }

  hostConnected(): void {
    this.renderLoop = new RenderLoop();
    this.renderLoop.frameRate = this.frameRate;
    this.renderLoop.events.on(RenderLoopEvent.Update, this.update.bind(this));
  }

  firstUpdated(): void {
    // this.host.imageElement.style.transition = `transform linear ${
    //   1 / this.frameRate
    // }s`;
    this.renderLoop.play();
  }

  private update() {
    if (!this.isActive) {
      return;
    }

    const containerRect = this.host.containerElement.getBoundingClientRect();

    this.previousContainerCenterY = this.currentContainerCenterY;
    this.currentContainerCenterY = Math.round(
      containerRect.y + containerRect.height * 0.5
    );

    // if scroll position has changed
    if (this.previousContainerCenterY !== this.currentContainerCenterY) {
      const viewportHeight = window.innerHeight;

      const minOffset = !isNaN(this.host.minOffset)
        ? this.host.minOffset
        : viewportHeight * -0.1;
      const maxOffset = !isNaN(this.host.maxOffset)
        ? this.host.maxOffset
        : viewportHeight * 0.1;

      const offset = viewportHeight * (this.host.speedModifier - 1) * 0.5;

      let y = remap(
        0,
        viewportHeight,
        this.currentContainerCenterY,
        -offset,
        offset
      );

      if (this.host.limitOffset) {
        y = clamp(minOffset, y, maxOffset);
      }

      y = Math.round(y);

      this.targetY = y;
    }

    // if not yet reached target y-offset
    if (this.currentY !== this.targetY) {
      this.previousY = this.currentY;

      this.updateCurrentY();
      // this.smoothUpdateCurrentY();

      this.render();
    }
  }

  private updateCurrentY() {
    this.currentY = this.targetY;
  }

  private smoothUpdateCurrentY() {
    // move n% from previous y towards target y (this smooths out the animation)
    this.currentY = Math.round(lerp(this.previousY, 0.2, this.targetY));

    // if difference is tiny, jump to target y
    if (Math.abs(this.currentY - this.targetY) < 0.01) {
      this.currentY = this.targetY;
    }
  }

  private render() {
    if (this.isActive) {
      this.host.imageElement.style.transform = `translate3d(0, ${this.currentY}px, 0)`;
    } else {
      this.host.imageElement.style.transform = '';
    }
  }

  hostDisconnected(): void {
    this.renderLoop.pause();
  }
}
