import { LitElement, ReactiveController } from 'lit';

export interface MouseState {
  x: number;
  y: number;
  relativeX: number;
  relativeY: number;
  isHovering: boolean;
}

export type MouseStateChangeCallback = (
  mouseState: MouseState,
  previousMouseState: MouseState
) => void;

export class MouseController implements ReactiveController {
  private host: LitElement;

  private _mouseState: MouseState = {
    x: 0,
    y: 0,
    relativeX: 0,
    relativeY: 0,
    isHovering: false,
  };
  private mouseStateChangeCallback: MouseStateChangeCallback;

  constructor(host: LitElement) {
    this.host = host;
    this.host.addController(this);
  }

  hostConnected(): void {
    window.addEventListener('mousemove', this.onMouseMove);
  }

  hostDisconnected(): void {
    window.removeEventListener('mousemove', this.onMouseMove);
  }

  private onMouseMove = (event: MouseEvent) => {
    const clientRect = this.host.getBoundingClientRect();

    const x = event.clientX;
    const y = event.clientY;
    const relativeX = x - clientRect.left;
    const relativeY = y - clientRect.left;

    const isHovering =
      x >= clientRect.left &&
      x <= clientRect.left + clientRect.width &&
      y >= clientRect.top &&
      y <= clientRect.top + clientRect.height;

    const previousMouseState = this._mouseState;
    this._mouseState = {
      x,
      y,
      relativeX,
      relativeY,
      isHovering,
    };

    this.mouseStateChangeCallback?.(this._mouseState, previousMouseState);
  };

  onMouseStateChange(callback: MouseStateChangeCallback) {
    this.mouseStateChangeCallback = callback;
  }

  get mouseState(): MouseState {
    return this._mouseState;
  }
}
