import { ViewController } from '../../../libs/view-controllers/view-controller';
import { StaggeredFadeInAnimation } from '../../animations';

export class PeopleGridController extends ViewController {
  private itemsToAnimate: HTMLElement[] = [];
  private staggeredFadeInAnimation = new StaggeredFadeInAnimation();

  onInit() {
    this.getElements();
    this.setupAnimation();
  }

  private getElements() {
    this.itemsToAnimate = Array.from(
      this.host.querySelectorAll<HTMLElement>(
        '.people-grid__title, .people-grid__item'
      )
    );
  }

  private setupAnimation() {
    this.staggeredFadeInAnimation.setItems(this.itemsToAnimate).setup();
  }
}

PeopleGridController.attachTo('.people-grid');
