import { css, html, LitElement, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { baseReset } from '../../../libs/styles/reset';
import {
  baseButtonStyles,
  ButtonAppearance,
  flatButtonStyles,
  raisedButtonStyles,
} from './base-button-styles';
import { LinkMixin } from '../../utils/mixins';

@customElement('cal-button')
export class CalButtonComponent extends LinkMixin(LitElement) {
  @property({
    attribute: 'appearance',
    reflect: true,
    type: String,
  })
  appearance: ButtonAppearance = 'basic';

  @property({
    attribute: 'no-line',
    reflect: true,
    type: Boolean,
  })
  noLine = false;

  @property({
    attribute: 'type',
    reflect: true,
    type: String,
  })
  type = 'button';

  protected render(): TemplateResult {
    if (this.href) {
      return html`
        <a class="cal-button button button--${this.appearance}"
           .href=${this.href}
           .target=${this.target}
           .tabIndex=${this.tabIndex}
           ?download=${this.canDownload}
        >
          ${this.renderButtonContent()}
        </a>
      `;
    } else {
      return html`
        <button class="cal-button button button--${this.appearance}"
                type=${this.type}
        >
          ${this.renderButtonContent()}
        </button>
      `;
    }
  }

  private renderButtonContent(): TemplateResult {
    return html`
      <span class="cal-button__flat-line button__flat-line"></span>
      <span class="cal-button__flat-arrow button__flat-arrow"></span>
      <span class="cal-button__text button__text">
        <slot></slot>
      </span>
    `;
  }

  static styles = [
    baseReset,
    baseButtonStyles,
    flatButtonStyles,
    raisedButtonStyles,
    css`
      :host {
        --button-background: transparent;
        --button-foreground: var(--cal-button-primary-color, #000000);
        --button-flat-arrow-color: var(--cal-button-primary-color, #000000);
        --button-font-weight: var(--cal-button-font-weight, normal);
        --button-text-transform: none;
        --button-font-size: var(--cal-button-font-size, 16px);
      }

      :host([appearance="flat"]) {
        --button-background: transparent;
        --button-foreground: var(--cal-button-primary-color, #000000);
        --button-font-weight: var(--cal-button-font-weight, bold);
        --button-flat-line-color: var(--cal-button-flat-line-color, var(--cal-button-primary-color, #000000));
        --button-flat-arrow-color: var(--cal-button-primary-color, #000000);
        --button-text-transform: uppercase;
        --button-font-size: var(--cal-button-font-size, 14px);
      }
      
      :host([appearance="flat"][no-line]) {
        --button-flat-padding-top: 10px;
        --button-flat-line-thickness: 0;
      }

      :host([appearance="raised"]) {
        --button-background: var(--cal-button-primary-color, #000000);
        --button-foreground: var(--cal-button-secondary-color, #ffffff);
        --button-font-weight: var(--cal-button-font-weight, bold);
        --button-text-transform: uppercase;
        --button-font-size: var(--cal-button-font-size, 14px);
      }

      .cal-button {
        width: inherit;
        text-decoration: none;
        text-align: center;
      }
    `,
  ];
}

declare global {
  interface HTMLElementTagNameMap {
    'cal-button': CalButtonComponent;
  }
}
