import { ReactiveController } from 'lit';
import { CalVideoPlayerComponent } from './cal-video-player';

export class VideoController implements ReactiveController {
  private host: CalVideoPlayerComponent;
  private _isPlaying = false;

  constructor(host: CalVideoPlayerComponent) {
    this.host = host;
    this.host.addController(this);
  }

  hostConnected(): void {}

  hostDisconnected(): void {}

  hostUpdate(): void {}

  hostUpdated(): void {}

  setup(): void {
    this.video.addEventListener('play', () => {
      this.setIsPlaying(true);
      this.host.dispatchEvent(
        new CustomEvent('onVideoPlay', {
          bubbles: true,
          cancelable: false,
        })
      );
    });

    this.video.addEventListener('pause', () => {
      this.setIsPlaying(false);
      this.host.dispatchEvent(
        new CustomEvent('onVideoPause', {
          bubbles: true,
          cancelable: false,
        })
      );
    });
  }

  togglePlay() {
    if (this.isPlaying) {
      this.pause();
    } else {
      this.play();
    }
  }

  play() {
    this.video.play();
  }

  pause() {
    this.video.pause();
  }

  private setIsPlaying(isPlaying: boolean) {
    this._isPlaying = isPlaying;
    this.host.isPlaying = this._isPlaying;
  }

  get isPlaying(): boolean {
    return this._isPlaying && !this.video.paused;
  }

  get video(): HTMLVideoElement {
    return this.host.video;
  }
}
