import { css, html, LitElement, TemplateResult } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { LinkMixin } from '../../utils/mixins';
import { PropertyValues } from '@lit/reactive-element';

@customElement('cal-image-carousel-item')
export class CalImageCarouselItemComponent extends LinkMixin(LitElement) {
  @state()
  order = 0;

  protected update(changedProperties: PropertyValues<this>): void {
    super.update(changedProperties);

    if (changedProperties.has('order')) {
      this.style.order = `${this.order}`;
    }
  }

  protected render(): TemplateResult {
    return html`
      <a href="${this.href}"
         target="${this.target}"
         tabindex="${this.tabIndex}"
         ?download="${this.canDownload}"
         class="link"
      >
        <slot></slot>
      </a>
    `;
  }

  static styles = [
    css`
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      
      .link {
        max-width: var(--cal-image-carousel-item-width, 50px);
        max-height: var(--cal-image-carousel-item-height, 30px);
      }
      
      ::slotted(*) {
        display: block;
        width: 100%;
        height: 100%;
        max-width: var(--cal-image-carousel-item-width, 50px);
        max-height: var(--cal-image-carousel-item-height, 30px);
      }
    `,
  ];
}

declare global {
  interface HTMLElementTagNameMap {
    'cal-image-carousel-item': CalImageCarouselItemComponent;
  }
}
