import { EventBus } from '../events';

export type RenderLoopUpdateCallback = (deltaTime: number) => void;

export enum RenderLoopEvent {
  Update = 'update',
}

export interface UpdateEvent {
  deltaTime: number;
}

export class RenderLoop {
  private startTime = -1;
  private lastTime = 0;
  events = new EventBus<RenderLoopEvent>();

  private _isPlaying = false;
  get isPlaying(): boolean {
    return this._isPlaying;
  }

  private _frameRate = {
    rate: 60,
    interval: 1000 / 60,
    now: 0,
    then: 0,
    elapsed: 0,
  };
  set frameRate(frameRate: number) {
    this._frameRate.rate = frameRate;
    this._frameRate.interval = 1000 / frameRate;
  }
  get frameRate() {
    return this._frameRate.rate;
  }

  constructor() {
    window.requestAnimationFrame(this.update.bind(this));
  }

  play() {
    this._isPlaying = true;
  }

  pause() {
    this._isPlaying = false;
  }

  private update(time: number) {
    if (this.startTime === -1) {
      this.startTime = time;
    }

    this._frameRate.now = performance.now();
    this._frameRate.elapsed = this._frameRate.now - this._frameRate.then;

    const correctedTime = time - this.startTime;
    const deltaTime = correctedTime - this.lastTime;
    this.lastTime = correctedTime;

    if (this._isPlaying) {
      if (this._frameRate.elapsed > this._frameRate.interval) {
        this._frameRate.then =
          this._frameRate.now -
          (this._frameRate.elapsed % this._frameRate.elapsed);

        this.updateRunning(deltaTime);
      }
    } else {
      this.updateIdle(deltaTime);
    }

    window.requestAnimationFrame(this.update.bind(this));
  }

  private updateRunning(deltaTime: number) {
    this.events.dispatch<UpdateEvent>(RenderLoopEvent.Update, {
      deltaTime,
    });
  }

  private updateIdle(deltaTime: number) {
    this.lastTime += deltaTime;
  }
}
