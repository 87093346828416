declare global {
  interface Window {
    ServiceLocator: ServiceLocator;
  }
}

export class ServiceLocator {
  private static services = new Map<string, any>();

  static provide<T>(id: string, service: T): void {
    this.services.set(id, service);
  }

  static get<T>(id: string): T {
    return this.services.get(id) as T;
  }
}

window.ServiceLocator = ServiceLocator;
