import { css, html, LitElement, TemplateResult } from 'lit';
import { baseReset } from '../../../../libs/styles/reset';
import { customElement, property } from 'lit/decorators.js';
import { MediaQuery, ResponsiveTargets } from '../../../styles/responsive';

@customElement('cal-header')
export class CalHeaderComponent extends LitElement {
  @property({
    attribute: 'open',
    reflect: true,
    type: Boolean,
  })
  isOpen = false;

  protected render(): TemplateResult {
    return html`
      <div class="cal-header">
        <div class="cal-header__inner">
          <div class="cal-header__logo">
            <slot name="logo"></slot>
          </div>

          <div class="cal-header__hamburger">
            <slot name="hamburger"></slot>
          </div>
        </div>
      </div>
    `;
  }

  static styles = [
    baseReset,
    css`
      :host {
        --cal-hamburger-line-color: var(--color-red);
        --cal-hamburger-line-spacing: 5px;
        --cal-hamburger-size: 32px;
      }
      
      :host([open]) {
        --cal-hamburger-line-color: var(--color-white);
        --cal-logo-color: var(--color-white);
      }

      .cal-header {
        width: 100%;
      }

      .cal-header__inner {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 12px;
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 12px;
        max-width: var(--content-max-width);
        margin-left: auto;
        margin-right: auto;
      }

      .cal-header__logo {
        height: 12px;
      }

      .cal-header__hamburger {
        transform-origin: top right;
        transition: transform ease-out .2s;
      }
      
      :host([open]) .cal-header__hamburger {
        transform: scale(1.1);
      }
    `,
    MediaQuery.range(
      ResponsiveTargets.lg,
      ResponsiveTargets.xl
    )(css`
      :host {
        --cal-hamburger-size: 40px;
        --cal-hamburger-line-spacing: 6px;
        --cal-hamburger-line-thickness: 2px;
        --cal-hamburger-bottom-line-width: 65%;
      }
      
      .cal-header__inner {
        padding-top: 19px;
        padding-left: 56px;
        padding-right: 56px;
        padding-bottom: 19px;
      }
      
      .cal-header__logo {
        height: 17px;
      }

      .cal-header ::slotted(img) {
        height: 17px;
      }
    `),
  ];
}

declare global {
  interface HTMLElementTagNameMap {
    'cal-header': CalHeaderComponent;
  }
}
