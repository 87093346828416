import { LitElement, ReactiveController } from 'lit';
import { EventBus } from '../../../libs/events';
import { Milliseconds } from '../../../libs/types';

interface TouchPosition {
  x: number;
  y: number;
}

export type SwipeEventName = 'swipeleft' | 'swiperight';

export class SwipeController implements ReactiveController {
  private readonly MAX_DURATION: Milliseconds = 400;
  private readonly MAX_Y_DELTA = 30;
  private readonly THRESHOLD = 10;

  private host: LitElement;

  private startTime: number;

  private startPosition: TouchPosition = {
    x: 0,
    y: 0,
  };
  private currentPosition: TouchPosition = {
    x: 0,
    y: 0,
  };

  public readonly events = new EventBus<SwipeEventName>();

  constructor(host: LitElement) {
    this.host = host;
    this.host.addController(this);
  }

  hostConnected(): void {
    this.host.addEventListener('touchstart', this.touchstartListener);
    this.host.addEventListener('touchmove', this.touchmoveListener);
    this.host.addEventListener('touchend', this.touchendListener);
  }

  hostDisconnected(): void {
    this.host.removeEventListener('touchstart', this.touchstartListener);
    this.host.removeEventListener('touchmove', this.touchmoveListener);
    this.host.removeEventListener('touchend', this.touchendListener);
  }

  hostUpdate(): void {}

  hostUpdated(): void {}

  private touchstartListener = (event: TouchEvent) => {
    this.startTime = Date.now();

    const touch = event.touches[0];
    this.startPosition.x = touch.clientX;
    this.startPosition.y = touch.clientY;
  };

  private touchmoveListener = (event: TouchEvent) => {
    const touch = event.touches[0];
    this.currentPosition.x = touch.clientX;
    this.currentPosition.y = touch.clientY;
  };

  private touchendListener = (event: TouchEvent) => {
    const deltaX = this.currentPosition.x - this.startPosition.x;
    const deltaY = this.currentPosition.y - this.startPosition.y;
    const duration = Date.now() - this.startTime;

    const movedTooFarOnY = Math.abs(deltaY) > this.MAX_Y_DELTA;
    const tookTooLong = duration > this.MAX_DURATION;

    if (movedTooFarOnY || tookTooLong) {
      return;
    }

    if (Math.abs(deltaX) > this.THRESHOLD) {
      if (deltaX > 0) {
        this.events.dispatch('swiperight', null);
      } else {
        this.events.dispatch('swipeleft', null);
      }
    }
  };
}
