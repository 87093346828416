import { ViewControllerId } from './view-controller-id';
import { ViewController } from './view-controller';
import { CssSelector } from '../types/css-selector';
import { ElementMutationObserver } from './element-mutation-observer';
import { IdGenerator } from '../identification/id-generator';

type ViewControllerClass = new (
  host: HTMLElement,
  id: ViewControllerId
) => ViewController;

export class ViewControllerRegistry {
  private static idGenerator: IdGenerator<ViewControllerId>;
  private static mutationObserver: ElementMutationObserver;

  private static registrations = new Map<CssSelector, ViewControllerClass>();
  private static instances = new Map<ViewControllerId, ViewController>();

  static setIdGenerator(idGenerator: IdGenerator<ViewControllerId>) {
    this.idGenerator = idGenerator;
  }

  static declare(
    selector: CssSelector,
    viewControllerClass: ViewControllerClass
  ) {
    this.registrations.set(selector, viewControllerClass);
  }

  static create(
    host: HTMLElement,
    viewControllerClass: ViewControllerClass
  ): ViewController {
    const id = this.idGenerator.next();
    host.dataset.viewControllerId = `${id}`;
    const instance = new viewControllerClass(host, id);

    this.instances.set(id, instance);

    return instance;
  }

  static get<T extends ViewController>(id: ViewControllerId): T {
    return this.instances.get(id) as T;
  }

  static register(elements?: HTMLElement[]) {
    const createdInstances: ViewController[] = [];

    this.registrations.forEach(
      (viewController: ViewControllerClass, selector: CssSelector) => {
        const hostElements =
          elements ||
          Array.from(document.querySelectorAll<HTMLElement>(selector));

        for (const element of hostElements) {
          // only register on elements that haven't already been used and that match selector
          if (
            element.dataset.viewControllerId === undefined &&
            element.matches(selector)
          ) {
            const instance = this.create(element, viewController);
            instance.onCreate();
            createdInstances.push(instance);
          }
        }
      }
    );

    // After ALL viewControllers were created
    window.requestAnimationFrame(() => {
      for (const instance of createdInstances) {
        try {
          instance.getDependencies();
          instance.onInit();
        } catch (error) {
          console.error(
            `Failed to initialize '${instance.constructor.name}' with id '${instance.id}'`
          );
          console.error(error);
        }
      }
    });
  }

  static observe(observerRoot: HTMLElement = document.body) {
    this.mutationObserver = new ElementMutationObserver(observerRoot);

    this.mutationObserver.onElementsAdded((addedElements: HTMLElement[]) =>
      this.register(addedElements)
    );

    this.register();
  }
}

// NOTE: This is for debugging purposes. With this you can access viewControllerRegistry in the console.
window.viewControllerRegistry = ViewControllerRegistry;

declare global {
  interface Window {
    viewControllerRegistry: ViewControllerRegistry;
  }
}
