import { css } from 'lit';

export type ButtonAppearance = 'basic' | 'flat' | 'raised';

export const baseButtonStyles = css`
  .button {
    background: transparent;
    border: 0;
    cursor: pointer;
    display: inline-block;
    background: var(--button-background, transparent);
    transition: background-color ease-out .2s;
    position: relative;
    font-family: inherit;
  }

  .button__text {
    color: var(--button-foreground, #000000);
    font-size: var(--button-font-size, 14px);
    line-height: 1;
    font-weight: var(--button-font-weight, bold);
    text-transform: var(--button-text-transform, uppercase);
    transition: color ease-out .2s;
  }

  .button__flat-arrow {
    display: none;
  }
`;

export const flatButtonStyles = css`
  .button--flat {
    padding-top: var(--button-flat-padding-top, 16px);
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 10px;
    position: relative;
    margin-top: var(--button-flat-line-thickness, 2px);
  }
  
  .button--flat .button__flat-line {
    position: absolute;
    left: 0;
    bottom: 100%;
    width: 80px;
    height: var(--button-flat-line-thickness, 2px);
    background-color: var(--button-flat-line-color, #000000);
    transition: background-color ease-out .2s;
  }

  .button--flat .button__flat-arrow {
    display: block;
    position: absolute;
    left: 0;
    bottom: 14px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 0 4px 6px;
    border-color: transparent transparent transparent var(--button-flat-arrow-color,#000000);
    transition: border-color ease-out .2s;
  }
`;

export const raisedButtonStyles = css`
  .button--raised {
    padding-top: 10px;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 10px;
  }
`;
