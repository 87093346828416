import { css, html, LitElement, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { MediaQuery, ResponsiveTargets } from '../../../styles/responsive';
import { baseReset } from '../../../../libs/styles/reset';

@customElement('cal-overlay')
export class CalOverlayComponent extends LitElement {
  private onClose() {
    this.dispatchEvent(
      new CustomEvent('onOverlayClose', {
        bubbles: true,
        cancelable: false,
      })
    );
  }

  protected render(): TemplateResult {
    return html`
      <div class="overlay">
        <div class="overlay__inner">
          <div class="overlay__close">
            <button class="close-button"
                    aria-label="close"
                    @click="${this.onClose.bind(this)}" 
            >
              <svg viewBox="0 0 31 32">
                <line y1="-0.5" x2="41.9575" y2="-0.5" transform="matrix(-0.715005 0.69912 -0.715005 -0.69912 30 1)" 
                      stroke="#C00000"
                />
                <line y1="-0.5" x2="41.9575" y2="-0.5" transform="matrix(0.715005 0.69912 -0.715005 0.69912 0 1.6665)" 
                      stroke="#C00000"
                />
              </svg>
            </button>
          </div>

          <div class="overlay__content">
            <slot></slot>
          </div>
        </div>
      </div>
    `;
  }

  static styles = [
    baseReset,
    css`
      :host {
        display: block;
        width: 100%;
      }

      .overlay {
        position: relative;
        width: 100%;
        background-color: var(--color-grey--warm-light);
      }

      .overlay__inner {
        
      }
      
      .overlay__close {
        position: absolute;
        top: 0;
        right: 0;
        padding-top: 24px;
        padding-right: 24px;
      }
      
      .close-button {
        width: 30px;
        height: 30px;
        border: 0;
        background: transparent;
        cursor: pointer;
      }

      ::slotted(*) {
        width: 100%;
        min-height: 500px;
        height: 80vh;
      }
    `,
    MediaQuery.range(
      ResponsiveTargets.md,
      ResponsiveTargets.xl
    )(css`
      .overlay__close {
        padding-top: 36px;
        padding-right: 36px;
      }
    `),
  ];
}

declare global {
  interface HTMLElementTagNameMap {
    'cal-overlay': CalOverlayComponent;
  }
}
