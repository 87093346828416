import { Directive, directive } from 'lit/directive.js';
import { ElementPart } from 'lit';
import { CountAnimation, CountAnimationUpdateEvent } from '../animations';

export class CountAnimationDirective extends Directive {
  private value = '';

  private element: HTMLElement;
  private intersectionObserver: IntersectionObserver;
  private animation: CountAnimation;

  constructor(elementPart: ElementPart) {
    super(elementPart);
    this.element = elementPart.element as HTMLElement;
    this.setupIntersectionObserver();
  }

  private setupIntersectionObserver() {
    this.intersectionObserver = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            this.animation?.doAnimation();
            this.intersectionObserver.unobserve(entry.target);
            this.intersectionObserver.disconnect();
            this.intersectionObserver = null;
          }
        }
      },
      {
        rootMargin: '16px',
        threshold: 0,
      }
    );
    this.intersectionObserver.observe(this.element);
  }

  update(part: ElementPart, [value]: [string]): void {
    const isFirstUpdate = !this.animation && value;
    if (isFirstUpdate) {
      this.value = value;
      this.render(this.value);
      this.createAnimation();
    }
  }

  private createAnimation() {
    this.animation = new CountAnimation();

    this.animation.events.on('start', () => {
      this.setFixedWidth();
    });

    this.animation.events.on('update', (event: CountAnimationUpdateEvent) => {
      this.render(`
          ${event.parsedNumber.leading}${event.number}${event.parsedNumber.trailing}
        `);
    });

    this.animation.events.on('complete', () => {
      this.element.style.width = '';
    });

    this.animation.prepareAnimation(this.value);
  }

  private setFixedWidth() {
    setTimeout(() => {
      this.render(this.value);
      window.requestAnimationFrame(() => {
        this.element.style.width = `${this.element.clientWidth}px`;
      });
    }, 0);
  }

  render(value: string): void {
    this.element.innerHTML = value;
  }
}

export const countAnimation = directive(CountAnimationDirective);
