/*
 * Import global styles
 */
import './main.scss';

/*
 * Import your components here
 */
import './components';
import './cms-plugins';
import './templates';
import './pages';

import { ViewControllerRegistry } from '../libs/view-controllers/view-controller-registry';
import { ServiceLocator } from '../libs/services';
import { Service } from './services';
import { InMemoryIdGenerator } from '../libs/identification/id-generator';
import { NavigationOverlayService, OverlayService } from './templates/base';
import { PageLoadService } from './utils/services/page-load-service';
import { ScrollBlockingService } from './utils/services/scroll-blocking-service';

window.onload = () => {
  main();
};

function main() {
  // Initialize Services
  // ==================================================
  const idGenerator = new InMemoryIdGenerator();
  ServiceLocator.provide(Service.IdGenerator, idGenerator);

  const pageLoadService = new PageLoadService();
  ServiceLocator.provide(Service.PageLoad, pageLoadService);

  const scrollBlockingService = new ScrollBlockingService(document.body);
  ServiceLocator.provide(Service.ScrollBlocking, scrollBlockingService);

  ServiceLocator.provide(
    Service.NavigationOverlay,
    new NavigationOverlayService()
  );

  ServiceLocator.provide(Service.Overlay, new OverlayService());

  // Start observing DOM for controllers
  // ==================================================
  ViewControllerRegistry.setIdGenerator(idGenerator);
  ViewControllerRegistry.observe();

  setTimeout(() => {
    pageLoadService.markLoaded();
  }, 0);
}
