import { css, html, LitElement, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { MediaQuery, ResponsiveTargets } from '../../styles/responsive';
import { baseReset } from '../../../libs/styles/reset';

@customElement('cal-news-with-filters')
export class CalNewsWithFiltersComponent extends LitElement {
  protected render(): TemplateResult {
    return html`
      <div class="cal-news-with-filters">
        <div class="cal-news-with-filters__filters">
          <slot name="filters"></slot>
        </div>

        <div class="cal-news-with-filters__list">
          <slot></slot>
        </div>
      </div>
    `;
  }

  static styles = [
    baseReset,
    css`
      :host {
        --cal-news-select-border-color: var(--color-red);
        --cal-news-select-arrow-color: var(--color-grey--warm);
        --cal-news-select-arrow-color-expanded: var(--color-red);
        --cal-news-select-value-color: var(--color-red);
        --cal-news-select-option-font-size: 24px;
        --cal-news-select-option-color: var(--color-grey--warm);
      }
      
      .cal-news-with-filters {
        max-width: var(--content-max-width);
        margin-left: auto;
        margin-right: auto;
      }
      
      .cal-news-with-filters__filters {
        margin-bottom: 20px;
      }
      
      ::slotted(cal-news-select) {
        display: block;
        margin-bottom: 10px;
      }
    `,
    MediaQuery.range(
      ResponsiveTargets.lg,
      ResponsiveTargets.xl
    )(css`
      .cal-news-with-filters__filters {
        margin-bottom: 26px;
      }

      ::slotted(cal-news-select) {
        margin-bottom: 4px;
      }
    `),
  ];
}

declare global {
  interface HTMLElementTagNameMap {
    'cal-news-with-filters': CalNewsWithFiltersComponent;
  }
}
