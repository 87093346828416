export class HeaderController {
  private host: HTMLElement;
  private headerWrapper: HTMLElement;

  private lastScrollY = -1;
  private lastScrollYBeforeScrollingDown = 0;

  constructor(host: HTMLElement) {
    this.host = host;
    this.getElements();
    this.addScrollListener();
  }

  private getElements() {
    this.headerWrapper = this.host.querySelector('.base__header');
  }

  private addScrollListener() {
    setTimeout(() => {
      this.lastScrollY = window.scrollY;
    }, 0);

    window.addEventListener(
      'scroll',
      () => {
        const currentScrollY = window.scrollY;

        if (currentScrollY > 0) {
          this.headerWrapper.classList.add('base__header--scrolled');

          if (currentScrollY > this.lastScrollY) {
            // scrolled down
            this.headerWrapper.classList.add('base__header--hide');
          } else if (currentScrollY < this.lastScrollY) {
            // scrolled up
            this.headerWrapper.classList.remove('base__header--hide');
          }
        } else {
          this.headerWrapper.classList.remove('base__header--scrolled');
          this.headerWrapper.classList.remove('base__header--hide');
        }

        this.lastScrollY = currentScrollY;
      },
      { passive: true }
    );
  }
}
