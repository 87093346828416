import { CalHeaderComponent } from './header';
import { CalHamburgerComponent } from './hamburger';
import { NavigationOverlayService } from './navigation-overlay';
import { ScrollBlockingService } from '../../utils/services/scroll-blocking-service';

export class NavigationController {
  private scrollBlockingService: ScrollBlockingService;
  private navigationOverlayService: NavigationOverlayService;
  private host: HTMLElement;

  private header: CalHeaderComponent;
  private hamburger: CalHamburgerComponent;

  constructor(
    host: HTMLElement,
    navigationOverlayService: NavigationOverlayService,
    scrollBlockingService: ScrollBlockingService
  ) {
    this.host = host;
    this.navigationOverlayService = navigationOverlayService;
    this.scrollBlockingService = scrollBlockingService;
    this.getElements();
    this.watchOpenState();
    this.setupHamburgerButton();
  }

  private getElements() {
    this.header = this.host.querySelector('cal-header');
    this.hamburger = this.host.querySelector('cal-hamburger');
  }

  private watchOpenState() {
    this.navigationOverlayService.isOpen.subscribe((isOpen: boolean) => {
      if (isOpen) {
        this.open();
      } else {
        this.close();
      }
    });
  }

  private setupHamburgerButton() {
    this.hamburger.addEventListener('onClick', () => {
      this.navigationOverlayService.toggleOpen();
    });
  }

  private open() {
    this.header.setAttribute('open', '');
    this.hamburger.setAttribute('open', '');
    this.host.classList.add('base--navigation-open');

    this.scrollBlockingService.block();
  }

  private close() {
    this.header.removeAttribute('open');
    this.hamburger.removeAttribute('open');
    this.host.classList.remove('base--navigation-open');

    this.scrollBlockingService.unblock();
  }
}
