import { ViewController } from '../../../libs/view-controllers/view-controller';
import { HorizontalScrollbarGlue } from '../../components/horizontal-scrollbar';

export class KpisOverviewController extends ViewController {
  private scrollbarGlue: HorizontalScrollbarGlue;

  onInit() {
    this.scrollbarGlue = new HorizontalScrollbarGlue(this);
  }
}

KpisOverviewController.attachTo('.kpis-overview');
