import { ViewController } from '../../../libs/view-controllers/view-controller';
import { CalParallaxImageComponent } from '../../components/parallax-image';
import { ReactiveController, ReactiveControllerHost } from 'lit';
import { ResponsiveController } from '../../utils/controllers';
import { MediaQuery, ResponsiveTargets } from '../../styles/responsive';

export class DarkMoodController
  extends ViewController
  implements ReactiveControllerHost
{
  private responsiveController = new ResponsiveController(this);

  parallaxImages: CalParallaxImageComponent[];

  onInit() {
    this.getElements();
    this.registerListeners();
    this.responsiveController.hostConnected();
  }

  private getElements() {
    this.parallaxImages = Array.from(
      this.host.querySelectorAll<CalParallaxImageComponent>(
        'cal-parallax-image'
      )
    );
  }

  private registerListeners() {
    this.responsiveController.onTargetChange(() => {
      this.setParallaxImagesActiveState();
    });
    this.setParallaxImagesActiveState();
  }

  private setParallaxImagesActiveState() {
    for (const parallaxImage of this.parallaxImages) {
      parallaxImage.isActive = MediaQuery.isActive(
        ResponsiveTargets.lg,
        ResponsiveTargets.xl
      );
    }
  }

  readonly updateComplete: Promise<boolean>;
  addController(controller: ReactiveController): void {}
  removeController(controller: ReactiveController): void {}
  requestUpdate(): void {}
}

DarkMoodController.attachTo('.dark-mood');
