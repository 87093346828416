import { CssSelector } from '../types';
import { ViewControllerRegistry } from './view-controller-registry';
import { ViewController } from './view-controller';

export function getChildViewController<T extends ViewController>(
  parent: HTMLElement,
  selector: CssSelector
): T {
  const hostElement = parent.querySelector<HTMLElement>(selector);
  return getViewControllerForElement(hostElement);
}

export function getChildViewControllers<T extends ViewController>(
  parent: HTMLElement,
  selector: CssSelector
): T[] {
  const viewControllers: T[] = [];
  const hostElements = Array.from(
    parent.querySelectorAll<HTMLElement>(selector)
  );
  for (const hostElement of hostElements) {
    const viewController = getViewControllerForElement<T>(hostElement);

    if (viewController) {
      viewControllers.push(viewController);
    }
  }
  return viewControllers;
}

export function getViewControllerForElement<T extends ViewController>(
  hostElement: HTMLElement
): T {
  const id = parseInt(hostElement.dataset.viewControllerId);
  return ViewControllerRegistry.get(id);
}
