import { ReactiveController } from 'lit';
import { CalNavigationGroupComponent } from './cal-navigation-group';

export class CalNavigationGroupExpandableController
  implements ReactiveController
{
  private host: CalNavigationGroupComponent;

  constructor(host: CalNavigationGroupComponent) {
    this.host = host;
    this.host.addController(this);
  }

  hostConnected() {
    // NOTE: if is initially open, set height of expandable container so close animation works
    if (this.host.isExpanded) {
      // Set timeout because we have to wait before first render finished,
      // to determine the open height.
      setTimeout(() => {
        this.setExpandedHeight();
      }, 0);
    }
  }

  toggleExpanded() {
    if (this.host.isExpanded) {
      this.collapse();
    } else {
      this.expand();
    }
  }

  expand() {
    this.host.isExpanded = true;
    this.setExpandedHeight();

    this.host.dispatchEvent(
      new CustomEvent('onExpand', {
        bubbles: true,
      })
    );
  }

  private setExpandedHeight() {
    this.host.expandableContainer.style.height = `${this.host.itemsContainer.clientHeight}px`;
  }

  collapse() {
    this.host.isExpanded = false;
    this.host.expandableContainer.style.height = '';

    this.host.dispatchEvent(
      new CustomEvent('onCollapse', {
        bubbles: true,
      })
    );
  }
}
