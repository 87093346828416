import { css, html, LitElement, TemplateResult } from 'lit';
import {
  customElement,
  property,
  queryAssignedElements,
} from 'lit/decorators.js';
import { LinkMixin } from '../../utils/mixins';
import { baseReset } from '../../../libs/styles/reset';
import { styleMap } from 'lit/directives/style-map.js';
import { CalIconComponent } from '../icon';
import { classMap } from 'lit/directives/class-map.js';
import { ifNotEmpty } from '../../utils/directives';

export type CalTeaserAppearance = 'square' | 'rectangle';

@customElement('cal-teaser')
export class CalTeaserComponent extends LinkMixin(LitElement) {
  @property({
    attribute: 'background-image',
    reflect: true,
    type: String,
  })
  backgroundImageUrl = '';

  @queryAssignedElements({ selector: 'cal-icon', slot: 'icon' })
  iconElements: CalIconComponent[];

  protected firstUpdated() {
    this.requestUpdate();
  }

  protected render(): TemplateResult {
    return html`
      <article class="cal-teaser ${classMap({
        'cal-teaser--has-icon': this.iconElements.length > 0,
        'cal-teaser--has-link': this.href.length > 0,
      })}"
      >
        ${this.renderBackgroundImage()}

        <div class="cal-teaser__rollover"></div>

        <a class="cal-teaser__link"
           .href=${ifNotEmpty(this.href)}
           .target=${this.target}
           .tabIndex=${this.tabIndex}
           ?download=${this.canDownload}
        >
          <div class="cal-teaser__inner">
            <div class="cal-teaser__main">
              <div class="cal-teaser__icon">
                <slot name="icon"></slot>
              </div>

              <div class="cal-teaser__text">
                <slot name="text"></slot>
              </div>
            </div>

            <div class="cal-teaser__button">
              <slot name="button"></slot>
            </div>
          </div>
        </a>
      </article>
    `;
  }

  private renderBackgroundImage(): TemplateResult {
    if (this.backgroundImageUrl) {
      return html`
        <div class="cal-teaser__background-image"
             style=${styleMap({
               'background-image': `url('${this.backgroundImageUrl}')`,
             })}
        ></div>
      `;
    } else {
      return html``;
    }
  }

  static styles = [
    baseReset,

    // Base styles
    css`
      :host {
        display: block;
        height: var(--cal-teaser-height, auto);
      }
      
      .cal-teaser {
        position: relative;
        width: 100%;
        transition: background-color ease-out .2s;
        background-color: var(--cal-teaser-background, #FFFFFF);
        height: var(--cal-teaser-height, auto);
        padding-top: calc(100% * var(--cal-teaser-aspect-ratio));
      }

      .cal-teaser__background-image {
        position: absolute;
        z-index: 5;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
      }

      .cal-teaser__rollover {
        position: absolute;
        z-index: 5;
        top: 0;
        left: 0;
        background-color: var(--cal-teaser-rollover-background, #000000);
        opacity: 0;
        width: 100%;
        height: 100%;
        transition: opacity ease-out .2s;
        display: none;
      }

      .cal-teaser:hover .cal-teaser__rollover {
        opacity: var(--cal-teaser-rollover-opacity, 0.15);
      }
      
      .cal-teaser--has-link .cal-teaser__rollover {
        display: block;
      }

      .cal-teaser__link {
        position: var(--cal-teaser-link-position, relative);
        z-index: 10;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        text-decoration: none;
        color: inherit;
      }

      .cal-teaser__inner {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding-top: var(--cal-teaser-padding-top, 24px);
        padding-left: var(--cal-teaser-padding-left, 24px);
        padding-right: var(--cal-teaser-padding-right, 24px);
        padding-bottom: var(--cal-teaser-padding-bottom, 24px);
      }

      .cal-teaser__main {
        flex-grow: var(--cal-teaser-text-grow, 1);
      }

      .cal-teaser__icon {
        display: none;
        position: relative;
        width: 100%;
        height: 70px;
        margin-bottom: 20px;
      }
      
      .cal-teaser--has-icon .cal-teaser__icon {
        display: block;
      }

      .cal-teaser__icon ::slotted(*) {
        position: absolute;
        display: block;
        font-size: 90px;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }

      .cal-teaser__text {
      }

      .cal-teaser__button {
        position: relative;
        flex-shrink: 0;
      }
      
      ::slotted(cal-button) {
        display: block;
        margin-top: var(--cal-teaser-button-margin-top, 0);
        margin-bottom: -10px;
        --cal-button-primary-color: var(--cal-teaser-button-foreground);
      }
    `,
  ];
}

declare global {
  interface HTMLElementTagNameMap {
    'cal-teaser': CalTeaserComponent;
  }
}
