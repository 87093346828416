import { ReactiveController } from 'lit';
import { CalImageCarouselComponent } from './cal-image-carousel';
import { ResponsiveController } from '../../utils/controllers';
import { Timer, TimerEvent } from '../../../libs/time';
import { MediaQuery, ResponsiveTargets } from '../../styles/responsive';
import { MouseController } from '../../utils/controllers/mouse-controller';
import anime from 'animejs';
import { Seconds } from '../../../libs/types';

export class ImageCarouselController implements ReactiveController {
  private responsiveController: ResponsiveController;
  private mouseController: MouseController;
  private host: CalImageCarouselComponent;

  private interval: Seconds;
  private timer: Timer;

  private _currentIndex = -1;
  private _visibleItemCount = 3;

  constructor(host: CalImageCarouselComponent) {
    this.host = host;
    this.host.addController(this);
  }

  firstUpdated() {
    this.setupTimer();
    this.setupResponsiveController();
    this.setupMouseController();
  }

  private setupResponsiveController() {
    this.responsiveController = new ResponsiveController(this.host);
    this.responsiveController.onTargetChange(this.onTargetChange.bind(this));
    this.onTargetChange();
  }

  private setupMouseController() {
    this.mouseController = new MouseController(this.host);

    this.mouseController.onMouseStateChange((mouseState) => {
      if (mouseState.isHovering) {
        this.timer.reset();
      }
    });
  }

  private onTargetChange() {
    const previousVisibleItemCount = this._visibleItemCount;

    this._visibleItemCount = 3;

    if (
      MediaQuery.isActive(ResponsiveTargets.md, ResponsiveTargets.md_landscape)
    ) {
      this._visibleItemCount = 4;
    } else if (
      MediaQuery.isActive(ResponsiveTargets.lg, ResponsiveTargets.xl)
    ) {
      this._visibleItemCount = 5;
    }

    if (this._visibleItemCount !== previousVisibleItemCount) {
      this.host.requestUpdate();
      this.reset();
    }
  }

  private reset() {
    this.timer.reset();
    this._currentIndex = -1;
    for (const item of this.host.items) {
      item.order = 0;
    }
  }

  setupTimer() {
    this.timer = new Timer(this.interval * 1000);

    this.timer.events.on(TimerEvent.Done, () => {
      this.goToNextItem();

      this.timer.reset();
      this.timer.play();
    });
    this.timer.play();
  }

  hostConnected(): void {}

  hostDisconnected(): void {}

  goToNextItem() {
    this.goToItem(this._currentIndex + 1);
  }

  goToItem(index: number) {
    if (this.isStatic) {
      return;
    }

    let safeIndex = index;
    if (index > this.maxIndex) {
      safeIndex = 0;
    }

    this._currentIndex = safeIndex;

    const translateX = `${-100 / this._visibleItemCount}%`;

    anime({
      targets: [this.host.slider],
      translateX,
      duration: 1000,
      easing: 'easeInOutCubic',
      complete: () => {
        const currentItem = this.host.items[this._currentIndex];

        if (currentItem) {
          currentItem.order++;
          this.host.slider.style.transform = '';
        }
      },
    });
  }

  get currentIndex(): number {
    return this._currentIndex;
  }

  get maxIndex(): number {
    return this.host.items.length - 1;
  }

  get isStatic(): boolean {
    return this.host.items.length <= this._visibleItemCount;
  }

  setInterval(interval: Seconds) {
    this.interval = interval;
    this.timer?.setDuration(interval * 1000);
  }
}
