import {
  CalNewsSelectComponent,
  NewsSelectChangeEvent,
} from '../../components/news';
import { ViewController } from '../../../libs/view-controllers/view-controller';

export interface NewsListAjaxControllerHost {
  outlet: HTMLElement;
  categorySelect: CalNewsSelectComponent;
  yearSelect: CalNewsSelectComponent;
}

export class NewsListAjaxController {
  private host: ViewController & NewsListAjaxControllerHost;

  private targetUrl = '';
  private backUrl = '';
  private pluginId = '';
  private filter = {
    category: '',
    year: '',
  };

  constructor(host: ViewController & NewsListAjaxControllerHost) {
    this.host = host;

    this.readHostData();
    this.readInitialFilterValues();
    this.registerListeners();
  }

  private readHostData() {
    this.targetUrl = this.host.host.dataset.url;
    this.backUrl = this.host.host.dataset.backUrl;
    this.pluginId = this.host.host.dataset.pluginId;
  }

  private readInitialFilterValues() {
    this.filter.category = this.host.categorySelect.value;
    this.filter.year = this.host.yearSelect.value;
  }

  private registerListeners() {
    this.host.host.addEventListener(
      'onNewsSelectChange',
      (event: CustomEvent<NewsSelectChangeEvent>) => {
        const eventData = event.detail;
        this.filter[eventData.name] = eventData.value;

        this.makeRequest();
      }
    );
  }

  private makeRequest() {
    return fetch(this.createUrl())
      .then((response) => response.text())
      .then((html: string) => {
        this.host.outlet.innerHTML = html;
      });
  }

  private createUrl(): string {
    const parts: string[] = [];

    parts.push(this.targetUrl);
    parts.push(`?plugin=${this.pluginId}`);
    parts.push(`&category=${this.filter.category}`);
    parts.push(`&year=${this.filter.year}`);
    parts.push(`&back=${this.backUrl}`);

    return parts.join('');
  }
}
