import { ViewController } from '../../../libs/view-controllers/view-controller';
import { HorizontalScrollbarGlue } from '../../components/horizontal-scrollbar';

export class StrategyOverviewController extends ViewController {
  private scrollbarGlue: HorizontalScrollbarGlue;

  onInit() {
    this.scrollbarGlue = new HorizontalScrollbarGlue(this);
  }
}

StrategyOverviewController.attachTo('.strategy-overview');
