import { RenderLoop, RenderLoopEvent, UpdateEvent } from '../rendering';
import { EventBus } from '../events';

export enum TimerEvent {
  Progress = 'progress',
  Done = 'done',
}

export class Timer {
  private renderLoop: RenderLoop;
  private progress = 0;
  private duration = 0;

  events = new EventBus<TimerEvent>();

  get isPlaying(): boolean {
    return this.renderLoop.isPlaying;
  }

  constructor(duration = 1000) {
    this.duration = duration;

    this.renderLoop = new RenderLoop();

    this.renderLoop.events.on(RenderLoopEvent.Update, (event: UpdateEvent) => {
      this.updateProgress(event.deltaTime);
    });
  }

  /**
   * @param duration in milliseconds
   */
  setDuration(duration: number): Timer {
    this.duration = duration;
    return this;
  }

  reset(): Timer {
    this.progress = 0;
    this.events.dispatch(TimerEvent.Progress, this.progress);
    return this;
  }

  play() {
    this.renderLoop.play();
  }

  pause() {
    this.renderLoop.pause();
  }

  private updateProgress(deltaTime: number) {
    this.progress = this.progress + deltaTime / this.duration;

    this.events.dispatch(TimerEvent.Progress, this.progress);

    if (this.progress >= 1) {
      this.done();
    }
  }

  private done() {
    this.renderLoop.pause();
    this.events.dispatch(TimerEvent.Done, null);
  }
}
