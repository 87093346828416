import { css, html, LitElement, PropertyValues, TemplateResult } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { baseReset } from '../../../../libs/styles/reset';
import { forceBrowserRedraw } from '../../../utils/force-browser-redraw';

@customElement('cal-hamburger')
export class CalHamburgerComponent extends LitElement {
  @property({
    attribute: 'open',
    type: Boolean,
    reflect: true,
  })
  isOpen = false;

  @query('.line--top')
  topLine!: HTMLElement;

  @query('.line--middle')
  middleLine!: HTMLElement;

  @query('.line--bottom')
  bottomLine!: HTMLElement;

  protected updated(changedProperties: PropertyValues<this>) {
    super.updated(changedProperties);

    if (changedProperties.has('isOpen')) {
      if (this.isOpen) {
        this.doOpenAnimation();
      } else {
        this.doCloseAnimation();
      }
    }
  }

  private doOpenAnimation() {
    this.resetAnimationStyle();
    forceBrowserRedraw();

    const animationStyle = `0.2s ease forwards`;
    this.topLine.style.animation = `line-top-close ${animationStyle}`;
    this.middleLine.style.animation = `line-middle-close ${animationStyle}`;
    this.bottomLine.style.animation = `line-bottom-close ${animationStyle}`;
  }

  private doCloseAnimation() {
    this.resetAnimationStyle();
    forceBrowserRedraw();

    const animationStyle = `0.2s ease forwards reverse`;
    this.topLine.style.animation = `line-top-close ${animationStyle}`;
    this.middleLine.style.animation = `line-middle-close ${animationStyle}`;
    this.bottomLine.style.animation = `line-bottom-close ${animationStyle}`;
  }

  private resetAnimationStyle() {
    this.topLine.style.animation = '';
    this.middleLine.style.animation = '';
    this.bottomLine.style.animation = '';
  }

  protected render(): TemplateResult {
    return html`
      <button class="button"
              @click=${this.handleClick.bind(this)}
      >
        <span class="line line--top"></span>
        <span class="line line--middle"></span>
        <span class="line line--bottom"></span>
      </button>
    `;
  }

  private handleClick(event: MouseEvent) {
    this.dispatchEvent(
      new CustomEvent('onClick', {
        bubbles: true,
        cancelable: true,
        detail: {
          originalEvent: event,
        },
      })
    );
  }

  static styles = [
    baseReset,
    css`
      :host {
        --animation-translate-y: calc(var(--cal-hamburger-line-thickness, 1px) + var(--cal-hamburger-line-spacing, 5px));
      }

      .button {
        position: relative;
        width: var(--cal-hamburger-size, 32px);
        height: var(--cal-hamburger-size, 32px);
        cursor: pointer;
        border: 0;
        background: none;
      }

      .line {
        width: 100%;
        height: var(--cal-hamburger-line-thickness, 1px);
        display: block;
        background-color: var(--cal-hamburger-line-color, black);
        margin-bottom: var(--cal-hamburger-line-spacing, 5px);
        transition: background-color ease-out .2s;
      }

      .line:last-child {
        margin-bottom: 0;
      }

      /* Top line */
      /* --------------------- */
      .line--top {
      }

      @keyframes line-top-close {
        0%{
          transform: none;
        }
        50% {
          transform: translateY(var(--animation-translate-y));
        }
        100% {
          transform: translateY(var(--animation-translate-y)) rotate(-45deg);
        }
      }

      /* Middle line */
      /* --------------------- */
      .line--middle {
      }

      @keyframes line-middle-close {
        0%, 50% {
          transform: none;
        }
        100% {
          transform: rotate(45deg);
        }
      }

      /* Bottom line */
      /* --------------------- */
      .line--bottom {
        width: var(--cal-hamburger-bottom-line-width, 50%);
      }

      @keyframes line-bottom-close {
        0% {
          transform: none;
        }
        50% {
          transform: translateY(calc(var(--animation-translate-y) * -1));
        }
        50.1% {
          transform: translateY(calc(var(--animation-translate-y) * -1)) scaleX(0);
        }
        100% {
          transform: translateY(calc(var(--animation-translate-y) * -1)) scaleX(0);
        }
      }
    `,
  ];
}

declare global {
  interface HTMLElementTagNameMap {
    'cal-hamburger': CalHamburgerComponent;
  }
}
