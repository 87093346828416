import { css, html, LitElement, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { baseReset } from '../../../../libs/styles/reset';
import { LinkMixin } from '../../../utils/mixins';

@customElement('cal-language-link')
export class CalLanguageLinkComponent extends LinkMixin(LitElement) {
  @property({
    attribute: 'active',
    type: Boolean,
    reflect: true,
  })
  isActive = false;

  protected render(): TemplateResult {
    return html`
      <a .href=${this.href} 
         .target=${this.target}
         class="cal-language-link">
        <slot></slot>
      </a>
    `;
  }

  static styles = [
    baseReset,
    css`
      .cal-language-link {
        font-size: var(--cal-language-link-font-size, 18px);
        line-height: 1.3;
        color: var(--cal-language-link-color, #000000);
        text-decoration: none;
        padding-right: var(--cal-language-link-padding-right, 12px);
        display: block;
        user-select: none;
        transition: color ease-out .2s;
      }
      
      .cal-language-link:hover {
        color: var(--cal-language-link-hover-color, var(--cal-language-link-color, currentColor));
      }

      :host([active]) .cal-language-link {
        color: var(--cal-language-link-active-color, var(--cal-language-link-color, black));
        padding-bottom: var(--cal-language-link-line-to-text-spacing, 12px);
        border-bottom: var(--cal-language-link-active-line-thickness, 0.5px) solid var(--cal-language-link-color, currentColor);
      }
    `,
  ];
}

declare global {
  interface HTMLElementTagNameMap {
    'cal-language-link': CalLanguageLinkComponent;
  }
}
