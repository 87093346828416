import { isNumber } from '../math';

export interface NumberParsingResult {
  leading: string;
  integer: string;
  fraction: string;
  number: number; // Integer and fraction joined by '.'
  trailing: string;
}

export class NumberParser {
  static parse(value: string): NumberParsingResult {
    const leadingCharacters = [];
    const numberCharacters = [];
    const floatingPointCharacters = [];
    const trailingCharacters = [];
    let foundLeadingCharacters = false;
    let foundNumberCharacters = false;
    let isFloatingPoint = false;
    let foundFloatingPointNumbers = false;

    for (let i = 0; i < value.length; i++) {
      const character = value[i];

      if (!foundLeadingCharacters) {
        if (!isNumber(character) || character === '0') {
          leadingCharacters.push(character);
        } else {
          numberCharacters.push(character);
          foundLeadingCharacters = true;
        }
      } else if (!foundNumberCharacters) {
        if (character === `'`) {
          continue;
        } else if (isNumber(character)) {
          numberCharacters.push(character);
        } else if (character === '.') {
          foundNumberCharacters = true;
          isFloatingPoint = true;
        } else {
          trailingCharacters.push(character);
          foundNumberCharacters = true;
        }
      } else if (isFloatingPoint && !foundFloatingPointNumbers) {
        if (isNumber(character)) {
          floatingPointCharacters.push(character);
        } else {
          trailingCharacters.push(character);
          foundFloatingPointNumbers = true;
        }
      } else {
        trailingCharacters.push(character);
      }
    }

    const leading = leadingCharacters.join('');
    const integer = numberCharacters.join('');
    const fraction = floatingPointCharacters.join('');
    const trailing = trailingCharacters.join('');

    const number = parseFloat(`${integer}.${fraction}`);

    return {
      leading,
      integer,
      fraction,
      number,
      trailing,
    };
  }
}
