import { ViewController } from '../../../libs/view-controllers/view-controller';
import { StaggeredFadeInAnimation } from '../../animations';

export class TeaserCarpetController extends ViewController {
  private itemsToAnimate: HTMLElement[] = [];
  private staggeredFadeInAnimation = new StaggeredFadeInAnimation();

  onInit() {
    this.getElements();
    this.setupAnimation();
  }

  private getElements() {
    this.itemsToAnimate = Array.from(
      this.host.querySelectorAll<HTMLElement>('.teaser-carpet__item')
    );
  }

  private setupAnimation() {
    this.staggeredFadeInAnimation.setItems(this.itemsToAnimate).setup();
  }
}

TeaserCarpetController.attachTo('.teaser-carpet');
