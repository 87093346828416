import { css, html, LitElement, PropertyValues, TemplateResult } from 'lit';
import {
  customElement,
  property,
  queryAssignedElements,
} from 'lit/decorators.js';
import { MediaQuery, ResponsiveTargets } from '../../../styles/responsive';
import { HeroSlide } from '../hero-slide';
import { styleMap } from 'lit/directives/style-map.js';
import { classMap } from 'lit/directives/class-map.js';
import { CalTeaserComponent } from '../../../components/teaser';

import './cal-teaser-slide.scss';
import { CalStatComponent } from '../../../components/stat';

export type CalTeaserSlideTeaserPosition = 'left' | 'right';
export type CalTeaserSlideImageAlign = 'left' | 'center' | 'right';

@customElement('cal-teaser-slide')
export class CalTeaserSlideComponent extends HeroSlide {
  @property({
    attribute: 'background-image-url',
    type: String,
  })
  backgroundImageUrl = '';

  @property({
    attribute: 'foreground-image-url',
    type: String,
  })
  foregroundImageUrl = '';

  @property({
    attribute: 'teaser-position',
    type: String,
    reflect: true,
  })
  teaserPosition: CalTeaserSlideTeaserPosition = 'left';

  @property({
    attribute: 'background-align',
    type: String,
    reflect: true,
  })
  backgroundAlign: CalTeaserSlideImageAlign = 'center';

  @property({
    attribute: 'foreground-align',
    type: String,
    reflect: true,
  })
  foregroundAlign: CalTeaserSlideImageAlign = 'center';

  @queryAssignedElements({
    slot: 'button',
  })
  buttons!: LitElement[];

  @queryAssignedElements({
    slot: 'headline',
  })
  headings!: HTMLHeadingElement[];

  @queryAssignedElements({
    slot: 'teaser',
  })
  teasers!: CalTeaserComponent[];

  @queryAssignedElements({
    slot: 'stats',
  })
  stats!: CalStatComponent[];

  protected firstUpdated(changedProperties: PropertyValues) {
    super.firstUpdated(changedProperties);
    this.addClassesToHeading();
    this.requestUpdate();
  }

  private addClassesToHeading() {
    if (this.headings?.length > 0) {
      const heading = this.headings[0];
      heading.classList.add('cal-teaser-slide-heading');

      if (this.buttons?.length <= 0) {
        heading.classList.add('cal-teaser-slide-heading--underlined');
      }
    }
  }

  protected render(): TemplateResult {
    return html`
      <div class="slide ${classMap({
        'slide--has-background-image': !!this.backgroundImageUrl,
        'slide--has-foreground-image': !!this.foregroundImageUrl,
        'slide--has-no-foreground-image': !this.foregroundImageUrl,
        'slide--no-teaser': this.teasers.length <= 0,
        'slide--has-siblings': !this.isStandalone,
        'slide--has-stats': this.stats.length > 0,
        'slide--has-no-stats': this.stats.length === 0,
      })}"
      >
        <div class="slide__top">
          <div class="slide__background-image"
               style="${styleMap({
                 backgroundImage: `url('${this.backgroundImageUrl}')`,
               })}"
          >
          </div>

          <div class="slide__foreground-image"
               style="${styleMap({
                 backgroundImage: `url('${this.foregroundImageUrl}')`,
               })}"
          ></div>

          <div class="slide__top-inner">
            <div class="slide__headline-and-button">
              <div class="slide__headline">
                <slot name="headline"></slot>
              </div>
              <div class="slide__button">
                <slot name="button"></slot>
              </div>
            </div>
          </div>
        </div>

        <div class="slide__bottom">
          <div class="slide__bottom-inner">
            <div class="slide__teaser">
              <slot name="teaser"></slot>
            </div>

            <div class="slide__stats">
              <slot name="stats"></slot>
            </div>
          </div>
        </div>
      </div>
    `;
  }

  static styles = [
    ...super.slideStyles,
    css`
      .slide {
        width: 100%;
        height: 100%;
      }

      .slide__top-inner,
      .slide__bottom-inner {
        position: relative;
        width: 100%;
        height: 100%;
        max-width: var(--content-max-width);
        margin-left: auto;
        margin-right: auto;
      }

      .slide__top {
        position: relative;
      }

      .slide__background-image {
        position: absolute;
        width: 100%;
        height: 100%;
        background-position: var(--cal-teaser-slide-background-focus-x, center) var(--cal-teaser-slide-background-focus-y, bottom);
        background-size: cover;
        background-repeat: no-repeat;
        z-index: 0;
      }

      .slide__foreground-image {
        position: relative;
        width: 100%;
        height: 75vh;
        overflow: hidden;
        background-position: var(--cal-teaser-slide-foreground-focus-x, center) var(--cal-teaser-slide-foreground-focus-y, top);
        background-repeat: no-repeat;
        background-size: cover;
      }

      .slide__headline-and-button {
        position: absolute;
        left: 0;
        right: 0;
        bottom: var(--content-side-padding-default);
        padding-left: var(--content-side-padding-default);
        padding-right: var(--content-side-padding-default);
        width: 100%;
      }

      .slide__headline {
        margin-bottom: 48px;
      }

      .slide__headline ::slotted(*) {
        font-size: 45px;
        line-height: 1.111;
      }

      .slide__button ::slotted(*) {
        width: 100%;
      }

      .slide__bottom {

      }

      .slide__teaser {

      }

      ::slotted(cal-teaser) {
        display: block;
        position: relative;
        z-index: 1;
      }

      .slide__stats {
        display: none;
        grid-template-columns: repeat(2, 1fr);
        padding-top: 56px;
        padding-left: var(--content-side-padding-default);
        padding-right: var(--content-side-padding-default);
        padding-bottom: 56px;
        background-color: var(--color-grey--warm-light);
        grid-row-gap: 56px;
        grid-column-gap: var(--content-side-padding-default);
      }

      .slide--has-stats .slide__stats {
        display: grid;
      }

      ::slotted(cal-stat) {
        --cal-stat-primary-color: var(--color-grey--warm);
      }
    `,

    MediaQuery.range(
      ResponsiveTargets.xs,
      ResponsiveTargets.sm
    )(css`
      ::slotted(cal-stat:nth-child(5)),
      ::slotted(cal-state:nth-child(6)) {
        display: none;
      }
    `),

    MediaQuery.range(
      ResponsiveTargets.xs,
      ResponsiveTargets.md
    )(css`
      .slide--has-siblings:not(.slide--has-stats) ::slotted(cal-teaser) {
        --cal-teaser-padding-bottom: 72px !important;
      }

      .slide--has-stats .slide__stats {
        padding-bottom: 88px;
      }
    `),

    MediaQuery.range(
      ResponsiveTargets.md,
      ResponsiveTargets.xl
    )(css`
      .slide__button ::slotted(*) {
        width: auto;
      }

      .slide__stats {
        grid-template-columns: repeat(3, 1fr);
        padding-left: 40px;
        padding-right: 40px;
      }
    `),

    // MediaQuery.range(ResponsiveTargets.md_landscape)(css`
    //   .slide--has-no-stats.slide {
    //     height: 100vh;
    //   }
    //
    //   .slide--has-no-stats .slide__top {
    //     height: 100%;
    //   }
    //
    //   .slide--has-no-stats .slide__foreground-image {
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     height: 100%;
    //   }
    //
    //   .slide--has-no-stats .slide__headline-and-button {
    //     position: absolute;
    //     top: calc(var(--header-height) + 48px);
    //     left: 0;
    //     right: 0;
    //     bottom: auto;
    //     padding-left: var(--content-side-padding-default);
    //     padding-right: var(--content-side-padding-default);
    //     width: 100%;
    //     z-index: 20;
    //   }
    //
    //   .slide--has-no-stats .slide__bottom {
    //     position: absolute;
    //     bottom: var(--content-side-padding-default);
    //     left: var(--content-side-padding-default);
    //     max-width: 624px;
    //     z-index: 5;
    //   }
    //
    //   .slide--has-no-stats .slide__bottom-inner {
    //     display: flex;
    //     flex-direction: column-reverse;
    //   }
    // `),

    MediaQuery.range(
      ResponsiveTargets.lg,
      ResponsiveTargets.xl
    )(css`
      :host {
        --slide-image-teaser-spacing: 40px;
        --slide-teaser-width: calc((var(--content-max-width) - var(--content-side-padding-default) * 2) * 0.5 - var(--slide-image-teaser-spacing));
      }

      .slide {
        height: 100%;
      }

      .slide__top {
        position: unset;
      }
      
      .slide__top-inner {
      }

      .slide__foreground-image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;
        pointer-events: none;
        width: auto;
        height: 100%;
        background-size: auto 100%;
        background-position: center;
      }
      
      .slide__background-image {
        background-size: auto 100%;
      }

      .slide__headline-and-button {
        position: absolute;
        max-width: 624px;
        top: 360px;
        left: 0;
        right: 0;
        bottom: auto;
        padding-left: calc(var(--content-side-padding-default) * 2);
        padding-right: var(--content-side-padding-default);
        width: 100%;
        z-index: 20;
      }

      :host([teaser-position="left"]) .slide__headline-and-button {
        left: auto;
        padding-left: var(--content-side-padding-default);
        padding-right: calc(var(--content-side-padding-default) * 2);
      }

      .slide__bottom {
        position: absolute;
        top: auto;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 5;
      }
      
      .slide__bottom-inner {
        display: flex;
        flex-direction: column-reverse;
        padding-bottom: 190px;
      }
      
      .slide--has-stats .slide__bottom-inner {
        padding-bottom: 100px;
      }
      
      :host([teaser-position="left"]) .slide__bottom-inner {
        padding-left: var(--content-side-padding-default);
      }

      :host([teaser-position="right"]) .slide__bottom-inner {
        padding-right: var(--content-side-padding-default);
        align-items: flex-end;
      }

      .slide__teaser {
        width: var(--slide-teaser-width, 624px);
      }

      :host([teaser-position="left"]) .slide--has-foreground-image ::slotted(cal-teaser) {
        --cal-teaser-padding-right: var(--cal-teaser-slide-image-overlap) !important;
      }

      :host([teaser-position="right"]) .slide--has-foreground-image ::slotted(cal-teaser) {
        --cal-teaser-padding-left: var(--cal-teaser-slide-image-overlap) !important;
      }

      .slide__stats {
        background-color: transparent;
        padding-right: var(--cal-teaser-slide-image-overlap, 24px);
        padding-left: 0;
        transform: scale(-1, -1);
        width: var(--slide-teaser-width, 624px);
      }

      :host([teaser-position="left"]) .slide__stats {
        transform: scale(1, -1);
        padding-right: 0;
        padding-left: var(--cal-teaser-slide-image-overlap, 24px);
      }

      ::slotted(cal-stat) {
        --cal-stat-value-font-size: 70px;
        --cal-stat-value-margin-bottom: 16px;
        transform: scale(-1, -1);
      }

      :host([teaser-position="left"]) ::slotted(cal-stat) {
        transform: scale(1, -1);
      }
    `),

    css`
      @media screen and (min-width: 2388px) {
        :host([foreground-align="left"]) .slide__foreground-image {
          background-position: left center;
        }

        :host([foreground-align="right"]) .slide__foreground-image {
          background-position: right center;
        }
        
        :host([background-align="left"]) .slide__background-image {
          background-position: left center;
        }

        :host([background-align="right"]) .slide__background-image {
          background-position: right center;
        }
      }
    `,
  ];
}

declare global {
  interface HTMLElementTagNameMap {
    'cal-teaser-slide': CalTeaserSlideComponent;
  }
}
