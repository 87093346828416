import { CalOverlayComponent } from './cal-overlay';
import { BehaviorSubject, Observable } from 'rxjs';

export class OverlayService {
  private currentOverlay$ = new BehaviorSubject<CalOverlayComponent>(null);

  openCAS(id: string) {
    const overlay = this.getCASOverlay(id);

    if (!overlay) {
      console.error(`Couldn't find CAS overlay!`);
      return;
    }

    overlay.addEventListener('onOverlayClose', this.onOverlayClose);

    this.currentOverlay$.next(overlay);
  }

  private getCASOverlay(id: string): CalOverlayComponent {
    const overlayTemplate = document.querySelector<HTMLTemplateElement>(
      `#cas-overlay-content-template[data-overlay-id="${id}"]`
    );

    const overlay = (
      overlayTemplate.content.cloneNode(true) as DocumentFragment
    ).querySelector<CalOverlayComponent>('.cas-overlay');

    return overlay;
  }

  private onOverlayClose = () => {
    this.close();
  };

  close() {
    const overlay = this.currentOverlay$.value;
    if (overlay) {
      overlay.removeEventListener('onOverlayClose', this.onOverlayClose);
    }

    this.currentOverlay$.next(null);
  }

  get currentOverlay(): Observable<CalOverlayComponent> {
    return this.currentOverlay$.asObservable();
  }
}
