import {
  IdGenerator,
  InMemoryIdGenerator,
} from '../identification/id-generator';

export type EventListener<E> = (event: E) => void;

export class EventBus<EventName = string> {
  private idGenerator: IdGenerator<number>;
  private listeners: Map<EventName, Map<number, EventListener<any>>> =
    new Map();

  constructor() {
    this.idGenerator = new InMemoryIdGenerator();
  }

  on<E = any>(eventName: EventName, callback: EventListener<E>) {
    const id = this.idGenerator.next();

    if (!this.listeners.has(eventName)) {
      this.listeners.set(eventName, new Map());
    }

    this.listeners.get(eventName).set(id, callback);
  }

  dispatch<E = any>(eventName: EventName, event: E) {
    if (this.listeners.has(eventName)) {
      const callbacks = this.listeners.get(eventName);

      for (const key of Array.from(callbacks.keys())) {
        const callback = callbacks.get(key);
        callback(event);
      }
    }
  }
}
