import { BehaviorSubject, Observable } from 'rxjs';

export class PageLoadService {
  private isLoaded$ = new BehaviorSubject(false);

  constructor() {}

  markLoaded() {
    this.isLoaded$.next(true);
  }

  get isLoaded(): Observable<boolean> {
    return this.isLoaded$.asObservable();
  }
}
