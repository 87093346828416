import { css, html, LitElement, PropertyValues, TemplateResult } from 'lit';
import {
  customElement,
  property,
  query,
  queryAssignedElements,
} from 'lit/decorators.js';
import { CalImageCarouselItemComponent } from './cal-image-carousel-item';
import { ImageCarouselController } from './image-carousel-controller';
import { MediaQuery, ResponsiveTargets } from '../../styles/responsive';
import { Seconds } from '../../../libs/types';
import { classMap } from 'lit/directives/class-map.js';

@customElement('cal-image-carousel')
export class CalImageCarouselComponent extends LitElement {
  private imageCarouselController = new ImageCarouselController(this);

  @property({
    attribute: 'interval',
    reflect: true,
    type: Number,
  })
  interval: Seconds = 3;

  @query('.image-carousel__slider')
  slider!: HTMLElement;

  @queryAssignedElements({ selector: 'cal-image-carousel-item' })
  items: CalImageCarouselItemComponent[];

  protected firstUpdated(changedProperties: PropertyValues<this>) {
    super.firstUpdated(changedProperties);
    this.imageCarouselController.firstUpdated();
  }

  protected update(changedProperties: PropertyValues<this>) {
    super.update(changedProperties);
    if (changedProperties.has('interval')) {
      this.imageCarouselController.setInterval(this.interval);
    }
  }

  protected render(): TemplateResult {
    return html`
      <div class="image-carousel ${classMap({
        'image-carousel--is-static': this.imageCarouselController.isStatic,
      })}">
        <div class="image-carousel__inner">
          <div class="image-carousel__mask">
            <div class="image-carousel__slider">
              <slot></slot>
            </div>
          </div>
        </div>
      </div>
    `;
  }

  static styles = [
    css`
        :host {
          display: block;
          width: 100%;
        }
      
        .image-carousel {
          
        }
        
        .image-carousel__inner {
          padding-top: 34px;
          padding-left: var(--content-side-padding-default);
          padding-right: var(--content-side-padding-default);
          padding-bottom: 34px;
          margin-left: auto;
          margin-right: auto;
          max-width: var(--content-max-width);
        }
        
        .image-carousel__mask {
          width: 100%;
          overflow: hidden;
        }
        
        .image-carousel__slider {
          display: flex;
          align-items: center;
          width: 100%;
        }
        
        .image-carousel--is-static .image-carousel__slider {
          justify-content: center;   
        }
        
        ::slotted(cal-image-carousel-item) {
          flex-shrink: 0;
          width: 33.3%;
          --cal-image-carousel-item-width: 60px;
          --cal-image-carousel-item-height: 20px;
        }
    `,

    MediaQuery.range(
      ResponsiveTargets.md,
      ResponsiveTargets.lg
    )(css`
      .image-carousel__inner {
        padding-top: 40px;
        padding-bottom: 40px;
      }
      
      ::slotted(cal-image-carousel-item) {
        width: 25%;
        --cal-image-carousel-item-width: 100px;
        --cal-image-carousel-item-height: 40px;
      }
    `),

    MediaQuery.range(
      ResponsiveTargets.lg,
      ResponsiveTargets.xl
    )(css`
      .image-carousel__inner {
        padding-top: 49px;
        padding-bottom: 49px;
      }
      
      ::slotted(cal-image-carousel-item) {
        width: 20%;
        --cal-image-carousel-item-width: 120px;
        --cal-image-carousel-item-height: 50px;
      }
    `),
  ];
}

declare global {
  interface HTMLElementTagNameMap {
    'cal-image-carousel': CalImageCarouselComponent;
  }
}
