import { css, html, LitElement, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { baseReset } from '../../../../libs/styles/reset';

@customElement('cal-social-media-links')
export class CalSocialMediaLinksComponent extends LitElement {
  protected render(): TemplateResult {
    return html`
      <div class="cal-social-media-links">
        <slot></slot>
      </div>
    `;
  }

  static styles = [
    baseReset,
    css`
      :host {
        display: block;
        width: 100%;
      }
      
      .cal-social-media-links {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      
      ::slotted(*) {
        list-style: none;
        margin-right: 32px;
      }
      
      ::slotted(*:last-child) {
        margin-right: 0;
      }
    `,
  ];
}

declare global {
  interface HTMLElementTagNameMap {
    'cal-social-media-links': CalSocialMediaLinksComponent;
  }
}
