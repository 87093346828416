import { ReactiveController, ReactiveControllerHost } from 'lit';
import { ResponsiveTarget, ResponsiveTargets } from '../../styles/responsive';

export class ResponsiveController implements ReactiveController {
  private host: ReactiveControllerHost;

  private _activeTarget: ResponsiveTarget = ResponsiveTargets.xs;
  private onTargetChangeCallback: (activeTarget: ResponsiveTarget) => void;
  private onResizeCallback: () => void;

  constructor(host: ReactiveControllerHost) {
    this.host = host;
    this.host.addController(this);
  }

  hostConnected(): void {
    window.addEventListener('resize', this.resizeHandler);
  }

  hostDisconnected(): void {
    window.removeEventListener('resize', this.resizeHandler);
  }

  private resizeHandler = () => {
    this.findCurrentTarget();
    this.onResizeCallback?.();
  };

  private findCurrentTarget() {
    for (const [key, responsiveTarget] of Object.entries(ResponsiveTargets)) {
      const currentWidth = window.innerWidth;

      if (
        currentWidth > responsiveTarget.minWidth &&
        currentWidth < responsiveTarget.maxWidth
      ) {
        if (this._activeTarget !== responsiveTarget) {
          this.onTargetChangeCallback?.(responsiveTarget);
        }

        this._activeTarget = responsiveTarget;
      }
    }
  }

  onTargetChange(callback: (activeTarget: ResponsiveTarget) => void) {
    this.onTargetChangeCallback = callback;
  }

  onResize(callback: () => void) {
    this.onResizeCallback = callback;
  }

  get activeTarget(): ResponsiveTarget {
    return this._activeTarget;
  }
}
