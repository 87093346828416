import { LitElement, ReactiveController } from 'lit';
import { CalNavigationGroupComponent } from './cal-navigation-group';
import { ResponsiveController } from '../../../utils/controllers';
import { MediaQuery, ResponsiveTargets } from '../../../styles/responsive';
import { CalNavigationGroupsContainer } from './cal-navigation-groups-container';

export class CalNavigationGroupsAccordionController
  implements ReactiveController
{
  private host: LitElement & CalNavigationGroupsContainer;
  private currentlyOpenGroup: CalNavigationGroupComponent | null = null;
  private responsiveController: ResponsiveController;

  constructor(host: LitElement & CalNavigationGroupsContainer) {
    this.host = host;
    this.responsiveController = new ResponsiveController(this.host);
    this.host.addController(this);
  }

  hostConnected() {
    this.setupAccordionBehavior();
    this.determineIfNavigationGroupsAreExpandable();
    this.responsiveController.onTargetChange(() => {
      this.determineIfNavigationGroupsAreExpandable();
    });
  }

  private determineIfNavigationGroupsAreExpandable() {
    if (MediaQuery.isActive(ResponsiveTargets.xs, ResponsiveTargets.md_landscape)) {
      for (const navigationGroup of this.host.navigationGroups) {
        if (navigationGroup.isExpanded) {
          this.currentlyOpenGroup = navigationGroup;
        }
        navigationGroup.isExpandable = true;
      }
    } else {
      for (const navigationGroup of this.host.navigationGroups) {
        navigationGroup.isExpandable = false;
      }
    }
  }

  private setupAccordionBehavior() {
    this.host.addEventListener('onExpand', (event) => {
      const target = event.target as CalNavigationGroupComponent;
      if (this.currentlyOpenGroup !== target) {
        this.currentlyOpenGroup?.collapse();
        this.currentlyOpenGroup = target;
      }
    });
  }
}
