import { css, html, LitElement, TemplateResult } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { RenderLoop, RenderLoopEvent } from '../../../libs/rendering';
import { styleMap } from 'lit/directives/style-map.js';
import { clamp, invLerp, lerp, remap } from '../../../libs/math';

export interface CalHorizontalScrollbarDragScrollEvent {
  newScrollPosition: number;
}

@customElement('cal-horizontal-scrollbar')
export class CalHorizontalScrollbarComponent extends LitElement {
  private scrollContainer: HTMLElement;
  private renderLoop: RenderLoop;

  private scrollContainerInfo = {
    width: 0,
    scrollWidth: 0,
    scrollPosition: 0,
  };

  private dragState = {
    startScrollPosition: 0,
    startX: 0,
    currentX: 0,
  };

  @state()
  private scrollbarWidthPercentage = 0;
  private scrollbarWidth = 0;

  @state()
  private scrollbarProgressPercentage = 0;

  @state()
  private scrollbarXPercentage = 0;
  private scrollbarX = 0;

  @property({
    attribute: 'hidden',
    reflect: true,
    type: Boolean,
  })
  private isHidden = false;

  @property({
    attribute: 'dragging',
    reflect: true,
    type: Boolean,
  })
  private isDragging = false;

  @query('.track')
  track!: HTMLElement;

  connectedCallback() {
    super.connectedCallback();
    this.renderLoop = new RenderLoop();
    this.renderLoop.events.on(RenderLoopEvent.Update, () => {
      if (this.scrollContainer) {
        const width = this.scrollContainer.clientWidth;
        const scrollWidth = this.scrollContainer.scrollWidth;
        const scrollPosition = this.scrollContainer.scrollLeft;

        const dimensionsHaveChanged =
          width !== this.scrollContainerInfo.width ||
          scrollWidth !== this.scrollContainerInfo.scrollWidth;

        const scrollPositionChanged =
          scrollPosition !== this.scrollContainerInfo.scrollPosition;

        if (dimensionsHaveChanged || scrollPositionChanged) {
          this.scrollContainerInfo.width = width;
          this.scrollContainerInfo.scrollWidth = scrollWidth;
          this.scrollContainerInfo.scrollPosition = scrollPosition;
          this.isHidden = scrollWidth === width;

          this.updateScrollbar();
        }
      }
    });
    this.renderLoop.play();

    window.addEventListener('mousemove', this.mouseMove);
    window.addEventListener('mouseup', this.mouseUp);
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    window.removeEventListener('mousemove', this.mouseMove);
    window.removeEventListener('mouseup', this.mouseUp);
  }

  private updateScrollbar() {
    this.scrollbarWidthPercentage = remap(
      0,
      this.scrollContainerInfo.scrollWidth,
      this.scrollContainerInfo.width,
      0,
      100
    );

    this.scrollbarProgressPercentage = invLerp(
      0,
      this.scrollContainerInfo.scrollPosition,
      this.scrollContainerInfo.scrollWidth - this.scrollContainerInfo.width
    );

    this.scrollbarXPercentage = lerp(
      0,
      this.scrollbarProgressPercentage,
      100 - this.scrollbarWidthPercentage
    );
  }

  setScrollContainer(scrollContainer: HTMLElement) {
    this.scrollContainer = scrollContainer;
  }

  private mouseDown = (event: MouseEvent) => {
    this.isDragging = true;
    this.dragState.startX = event.clientX;
    this.dragState.startScrollPosition =
      this.scrollContainerInfo.scrollPosition;
  };

  private mouseMove = (event: MouseEvent) => {
    if (this.isDragging) {
      const currentX = event.clientX;
      const diffX = currentX - this.dragState.startX;

      const scrollbarWidth =
        this.scrollbarWidthPercentage * 0.01 * this.track.clientWidth;

      let newScrollPosition =
        remap(
          0,
          this.track.clientWidth - scrollbarWidth,
          diffX,
          0,
          this.scrollContainerInfo.scrollWidth - this.scrollContainerInfo.width
        ) + this.dragState.startScrollPosition;

      newScrollPosition = clamp(
        0,
        newScrollPosition,
        this.scrollContainerInfo.scrollWidth
      );

      this.dispatchEvent(
        new CustomEvent<CalHorizontalScrollbarDragScrollEvent>('onDragScroll', {
          cancelable: false,
          bubbles: true,
          detail: {
            newScrollPosition,
          },
        })
      );
    }
  };

  private mouseUp = (event: MouseEvent) => {
    this.isDragging = false;
  };

  protected render(): TemplateResult {
    return html`
      <div class="track">
        <div class="knob-wrapper"
             style="${styleMap({
               transform: `translateX(${this.scrollbarXPercentage}%)`,
             })}"
        >
          <div class="knob"
               style="${styleMap({
                 width: `${this.scrollbarWidthPercentage}%`,
               })}"
               @mousedown="${this.mouseDown}"
          ></div>
        </div>
      </div>
    `;
  }

  static styles = [
    css`
      :host {
        display: block;
        width: 100%;
        transition: opacity ease-out .2s;
        padding-top: 20px;
        margin-top: -20px;
        overflow: hidden;
      }
      
      :host([hidden]) {
        opacity: 0;
      }

      .track {
        background-color: var(--cal-horizontal-scrollbar-track-color, #555555);
        height: var(--cal-horizontal-scrollbar-height, 1px);
      }

      .knob-wrapper {
        width: 100%;
        height: 100%;
      }

      .knob {
        background-color: var(--cal-horizontal-scrollbar-knob-color, #000000);
        height: 100%;
        width: 0;
        transform-origin: left bottom;
        transition: transform ease-out .2s;
        cursor: pointer;
      }
      
      :host(:hover) .knob,
      :host([dragging]) .knob {
        transform: scaleY(10);
      }
    `,
  ];
}

declare global {
  interface HTMLElementTagNameMap {
    'cal-horizontal-scrollbar': CalHorizontalScrollbarComponent;
  }
}
