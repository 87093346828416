import { ViewController } from '../../../libs/view-controllers/view-controller';
import { CalNewsSelectComponent } from '../../components/news';
import {
  NewsListAjaxController,
  NewsListAjaxControllerHost,
} from '../../utils/controllers/news-list-ajax-controller';

export class PublicationsListController
  extends ViewController
  implements NewsListAjaxControllerHost
{
  private controller: NewsListAjaxController;

  outlet: HTMLElement;
  categorySelect: CalNewsSelectComponent;
  yearSelect: CalNewsSelectComponent;

  onInit() {
    this.getElements();
    this.controller = new NewsListAjaxController(this);
  }

  private getElements() {
    this.outlet = this.host.querySelector('.publications-list__outlet');

    this.categorySelect = this.host.querySelector(
      'cal-news-select[name="category"]'
    );
    this.yearSelect = this.host.querySelector('cal-news-select[name="year"]');
  }
}

PublicationsListController.attachTo('.publications-list');
