import { css, html, LitElement, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';

@customElement('cal-stat-unit')
export class CalStatUnitComponent extends LitElement {
  protected render(): TemplateResult {
    return html`
      <slot></slot>
    `;
  }

  static styles = [
    css`
      :host {
        display: block;
        font-size: var(--cal-stat-unit-font-size, 16px);
        font-weight: var(--cal-stat-unit-font-weight, bold);
        line-height: 1.3125;
      }
    `,
  ];
}

declare global {
  interface HTMLElementTagNameMap {
    'cal-stat-unit': CalStatUnitComponent;
  }
}
