import anime from 'animejs';

export class StaggeredFadeInAnimation {
  private itemsWereSet = false;
  private items: HTMLElement[] = [];
  private observedItemCount = 0;
  private shouldPrepareItems = false;

  setup() {
    if (!this.itemsWereSet) {
      throw new Error(`Forgot to set items before setup!`);
    }

    if (this.shouldPrepareItems) {
      this._prepareItems();
    }
    this.setupIntersectionObserver();
  }

  private _prepareItems() {
    anime.set(this.items, {
      opacity: 0,
    });
  }

  private setupIntersectionObserver() {
    const intersectionObserver = new IntersectionObserver(
      (entries) => {
        const animationGroup: HTMLElement[] = [];

        for (const entry of entries) {
          if (entry.isIntersecting) {
            const target = entry.target as HTMLElement;
            animationGroup.push(target);
            intersectionObserver.unobserve(target);
            this.observedItemCount--;
          }
        }

        if (animationGroup.length > 0) {
          this.doAnimation(animationGroup);
        }

        if (this.observedItemCount === 0) {
          intersectionObserver.disconnect();
        }
      },
      {
        threshold: 0.5,
      }
    );

    for (const item of this.items) {
      intersectionObserver.observe(item);
      this.observedItemCount++;
    }
  }

  private doAnimation(targetGroup: HTMLElement[]) {
    anime({
      targets: targetGroup,
      duration: 400,
      delay: anime.stagger(50),
      easing: 'easeInSine',
      opacity: 1,
    });
  }

  setItems(items: HTMLElement[]): StaggeredFadeInAnimation {
    this.itemsWereSet = true;
    this.items = items;

    return this;
  }

  prepareItems(): StaggeredFadeInAnimation {
    this.shouldPrepareItems = true;

    return this;
  }
}
