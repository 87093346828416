import { NavigationController } from './navigation-controller';
import { HeaderController } from './header-controller';
import { ViewController } from '../../../libs/view-controllers/view-controller';
import { AnchorScrollController } from './anchor-scroll-controller';
import { PageLoadService } from '../../utils/services/page-load-service';
import { ServiceLocator } from '../../../libs/services';
import { Service } from '../../services';
import { NavigationOverlayService } from './navigation-overlay';
import { ScrollBlockingService } from '../../utils/services/scroll-blocking-service';

export class BaseController extends ViewController {
  private pageLoadService: PageLoadService;
  private navigationOverlayService: NavigationOverlayService;
  private scrollBlockingService: ScrollBlockingService;

  private headerController: HeaderController;
  private navigationController: NavigationController;
  private anchorScrollController: AnchorScrollController;

  getDependencies() {
    this.pageLoadService = ServiceLocator.get(Service.PageLoad);
    this.navigationOverlayService = ServiceLocator.get(
      Service.NavigationOverlay
    );
    this.scrollBlockingService = ServiceLocator.get(Service.ScrollBlocking);
  }

  onInit() {
    this.headerController = new HeaderController(this.host);
    this.navigationController = new NavigationController(
      this.host,
      this.navigationOverlayService,
      this.scrollBlockingService
    );
    this.anchorScrollController = new AnchorScrollController(
      this.host,
      this.pageLoadService,
      this.navigationOverlayService
    );
  }
}

BaseController.attachTo('.base');
