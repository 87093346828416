export class ScrollBlockingService {
  private scrollable: HTMLElement;
  private scrollYBeforeBlock = 0;
  private _isBlocked = false;

  constructor(scrollable: HTMLElement) {
    this.scrollable = scrollable;
  }

  block() {
    this.scrollYBeforeBlock = window.scrollY;

    this.scrollable.style.position = 'fixed';
    this.scrollable.style.top = `-${this.scrollYBeforeBlock}px`;

    this._isBlocked = true;
  }

  unblock() {
    this.scrollable.style.position = '';
    this.scrollable.style.top = '';

    window.scrollTo(0, this.scrollYBeforeBlock);

    this._isBlocked = false;
  }

  get isBlocked(): boolean {
    return this._isBlocked;
  }
}
