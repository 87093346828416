import { conditionalCSSClass } from '../dom';
import { ViewControllerRegistry } from './view-controller-registry';
import { CssSelector } from '../types';
import { ViewControllerId } from './view-controller-id';
import {
  getChildViewController,
  getChildViewControllers,
  getViewControllerForElement,
} from './view-controller-utils';

export class ViewController<T extends HTMLElement = HTMLElement> {
  public readonly host: T;
  id: ViewControllerId;

  constructor(host: T, id: ViewControllerId) {
    this.host = host;
    this.id = id;
  }

  onCreate() {
    // NOTE: empty implementation on purpose
  }

  getDependencies() {
    // NOTE: empty implementation on purpose
  }

  onInit() {
    // NOTE: empty implementation on purpose
  }

  hostClass(cssClass: string, condition: boolean) {
    conditionalCSSClass(this.host, cssClass, condition);
  }

  getChildComponent<T extends ViewController>(selector: CssSelector): T {
    return getChildViewController<T>(this.host, selector);
  }

  getChildComponents<T extends ViewController>(selector: CssSelector): T[] {
    return getChildViewControllers<T>(this.host, selector);
  }

  getComponentForElement<T extends ViewController>(element: HTMLElement) {
    return getViewControllerForElement<T>(element);
  }

  static attachTo(selector: CssSelector) {
    ViewControllerRegistry.declare(selector, this);
  }
}
