import { css, html, LitElement, PropertyValues, TemplateResult } from 'lit';
import { customElement, queryAssignedNodes } from 'lit/decorators.js';
import { countAnimation } from '../../utils/directives/count-animation-directive';

@customElement('cal-stat-value')
export class CalStatValueComponent extends LitElement {
  private initialValue = '';
  private displayValue = '';

  @queryAssignedNodes()
  nodes!: Node[];

  protected firstUpdated(changedProperties: PropertyValues) {
    super.firstUpdated(changedProperties);

    for (const node of this.nodes) {
      if (node.nodeType === Node.TEXT_NODE) {
        const trimmedContent = node.textContent.trim();
        if (trimmedContent) {
          this.initialValue = trimmedContent;
          this.displayValue = this.initialValue;
          this.requestUpdate();
          break;
        }
      }
    }
  }

  protected render(): TemplateResult {
    return html`
      <span class="initial-value">
        <slot></slot>
      </span>
      <span class="display-value" ${countAnimation(this.displayValue)}>
      </span>
    `;
  }

  static styles = [
    css`
      :host {
        position: relative;
        display: block;
        font-size: var(--cal-stat-value-font-size, 50px);
        line-height: 0.9;
        padding-bottom: var(--cal-stat-value-padding-bottom, 12px);
      }
      
      :host::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 80px;
        height: 2px;
        background-color: currentColor;
      }
      
      .initial-value {
        display: none;
      }
      
      .display-value {
        display: block;
      }
    `,
  ];
}

declare global {
  interface HTMLElementTagNameMap {
    'cal-stat-value': CalStatValueComponent;
  }
}
