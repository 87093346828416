import {
  CalHorizontalScrollbarComponent,
  CalHorizontalScrollbarDragScrollEvent,
} from './cal-horizontal-scrollbar';
import { ViewController } from '../../../libs/view-controllers/view-controller';

export class HorizontalScrollbarGlue {
  private viewController: ViewController;
  private hostElement: HTMLElement;

  private scrollContainer: HTMLElement;
  private scrollbar: CalHorizontalScrollbarComponent;

  constructor(viewController: ViewController) {
    this.viewController = viewController;
    this.hostElement = this.viewController.host;

    this.getElements();
    this.glueTogether();
    this.registerListeners();
  }

  private getElements() {
    this.scrollContainer = this.hostElement.querySelector(
      '[data-scroll-container]'
    );

    this.scrollbar = this.hostElement.querySelector('cal-horizontal-scrollbar');
  }

  private glueTogether() {
    this.scrollbar.setScrollContainer(this.scrollContainer);
  }

  private registerListeners() {
    this.viewController.host.addEventListener(
      'onDragScroll',
      (event: CustomEvent<CalHorizontalScrollbarDragScrollEvent>) => {
        this.scrollContainer.scrollLeft = event.detail.newScrollPosition;
      }
    );
  }
}
