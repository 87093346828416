import { css } from 'lit';

export const baseReset = css`
    *, *::before, *::after {
        box-sizing: inherit;
        font-family: inherit;
        letter-spacing: inherit;
        margin: 0;
        padding: 0;
    }
`;
