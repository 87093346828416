import { ViewController } from '../../../libs/view-controllers/view-controller';
import { OverlayService } from '../../templates/base';
import { ServiceLocator } from '../../../libs/services';
import { Service } from '../../services';

export class CalOverlayLink extends ViewController<HTMLAnchorElement> {
  private overlayService: OverlayService;

  getDependencies() {
    this.overlayService = ServiceLocator.get(Service.Overlay);
  }

  onInit() {
    this.registerListeners();
  }

  private registerListeners() {
    this.host.addEventListener('click', (event: MouseEvent) => {
      event.preventDefault();

      const overlayId = this.host.dataset['overlayLink'];
      this.overlayService.openCAS(overlayId);
    });
  }
}

CalOverlayLink.attachTo('[data-overlay-link]');
