import { BehaviorSubject, Observable } from 'rxjs';

export class NavigationOverlayService {
  private isOpen$ = new BehaviorSubject(false);

  toggleOpen() {
    if (this.isOpen$.value) {
      this.close();
    } else {
      this.open();
    }
  }

  open() {
    this.isOpen$.next(true);
  }

  close() {
    this.isOpen$.next(false);
  }

  get isOpen(): Observable<boolean> {
    return this.isOpen$.asObservable();
  }
}
