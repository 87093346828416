import { css, html, LitElement, PropertyValues, TemplateResult } from 'lit';
import {
  customElement,
  property,
  queryAssignedElements,
} from 'lit/decorators.js';
import { map } from 'lit/directives/map.js';
import { HeroSliderController } from './hero-slider-controller';
import { classMap } from 'lit/directives/class-map.js';
import { MediaQuery, ResponsiveTargets } from '../../styles/responsive';
import { baseReset } from '../../../libs/styles/reset';
import { HeroSlide } from './hero-slide';

import './cal-hero-slider.scss';

@customElement('cal-hero-slider')
export class CalHeroSliderComponent extends LitElement {
  private heroSliderController = new HeroSliderController(this);

  @property({
    attribute: 'autoplay',
    reflect: true,
    type: Boolean,
  })
  shouldAutoplay = false;

  @property({
    attribute: 'autoplay-duration',
    reflect: true,
    type: Number,
  })
  autoplayDuration = 10;

  @queryAssignedElements({
    selector: 'cal-teaser-slide, cal-video-slide, cal-text-slide',
  })
  slides!: HeroSlide[];

  protected firstUpdated(changeProperties: PropertyValues) {
    super.firstUpdated(changeProperties);
    this.determineFirstActiveIndex();
    this.informSlideIfStandalone();
    this.setupIntersectionObserver();
    this.listenForVideoEvents();
  }

  private determineFirstActiveIndex() {
    let activeIndex = 0;

    for (let i = 0; i < this.slides.length; i++) {
      const slide = this.slides[i];
      if (slide.isActive) {
        activeIndex = i;
      }
    }

    this.heroSliderController.goToSlide(activeIndex);
  }

  private informSlideIfStandalone() {
    if (this.slides.length === 1) {
      this.slides[0].isStandalone = true;
    }
  }

  private setupIntersectionObserver() {
    const intersectionObserver = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          this.heroSliderController.restartTimer();
        } else {
          this.heroSliderController.stopTimer();
        }
      },
      {
        threshold: 0,
      }
    );

    intersectionObserver.observe(this);
  }

  private listenForVideoEvents() {
    this.addEventListener('onVideoPlay', () => {
      this.heroSliderController.stopTimer();
    });

    this.addEventListener('onVideoPause', () => {
      this.heroSliderController.restartTimer();
    });
  }

  private onDotClick(index: number) {
    this.heroSliderController.restartTimer();
    this.heroSliderController.goToSlide(index);
    this.scrollToTopOfSlider();
  }

  private scrollToTopOfSlider() {
    const y = this.getBoundingClientRect().top + window.scrollY;

    window.scroll({
      left: 0,
      top: y,
      behavior: 'smooth',
    });
  }

  protected render(): TemplateResult {
    return html`
      <div class="slider">
        <div class="slider__inner">
          <div class="slider__slides">
            <slot></slot>
          </div>
          
          <div class="slider__overlay">
            ${this.renderDots()}
          </div>
        </div>
      </div>
    `;
  }

  private renderDots(): TemplateResult {
    if (this.slides.length <= 1) {
      return html``;
    }

    return html`
      <div class="slider__dots">
        ${map(
          this.slides,
          (slide, index) => html`
          <button class="slider__dot ${classMap({
            'slider__dot--active':
              index === this.heroSliderController.currentSlideIndex,
          })}"
               @click=${this.onDotClick.bind(this, index)}
          ></button>
        `
        )}
      </div>
    `;
  }

  static styles = [
    baseReset,
    css`
      :host {
        display: block;
        position: relative;
        z-index: 0;
        min-height: 300px;
      }
      
      .slider {
        background-color: var(--color-grey--warm-light);
      }
      
      .slider__inner {
        position: relative;
      }
      
      .slider__slides {
        
      }
      
      ::slotted(cal-hero-slide) {
        position: relative;
      }
      
      ::slotted(cal-hero-slide[active]) {
        z-index: 1;
      }
      
      .slider__overlay {
        position: relative;
        z-index: 100;
      }
      
      .slider__dots {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        padding-bottom: 40px;
        pointer-events: none;
      }
      
      .slider__dot {
        position: relative;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: var(--color-red);
        margin-left: 8px;
        margin-right: 8px;
        cursor: pointer;
        pointer-events: all;
        transition: background-color ease-out .2s;
        border: 0;
      }
      .slider__dot--active {
        background-color: var(--color-black);
      }
      
      .slider__dot::before {
        content: '';
        display: block;
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    `,

    MediaQuery.range(
      ResponsiveTargets.md,
      ResponsiveTargets.xl
    )(css`
      
    `),

    MediaQuery.range(
      ResponsiveTargets.lg,
      ResponsiveTargets.xl
    )(css`
      .slider__inner {
        max-height: var(--cal-hero-slider-height, 800px);
      }
      
      .slider__slides {
        height: var(--cal-hero-slider-height, 800px);
      }
       
      ::slotted(cal-hero-slide) {
        position: absolute;
        top: 0;
        left: 0;
      }
      
      .slider__dot {
        width: 12px;
        height: 12px;
        margin-left: 16px;
        margin-right: 16px;
      }
      
      .slider__dot--active {
        background-color: var(--color-white);
      }

      .slider__dot::before {
        width: 32px;
        height: 32px;
      }
    `),
  ];
}

declare global {
  interface HTMLElementTagNameMap {
    'cal-hero-slider': CalHeroSliderComponent;
  }
}
