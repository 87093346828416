import { css, html, LitElement, PropertyValues, TemplateResult } from 'lit';
import {
  customElement,
  property,
  query,
  queryAssignedElements,
  state,
} from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { map } from 'lit/directives/map.js';

import { CalNewsSelectOptionComponent } from './cal-news-select-option';
import { baseReset } from '../../../libs/styles/reset';
import { ResponsiveController } from '../../utils/controllers';
import { MediaQuery, ResponsiveTargets } from '../../styles/responsive';

interface SelectOption {
  element: CalNewsSelectOptionComponent;
}

export interface NewsSelectChangeEvent {
  previousValue: string;
  value: string;
  name: string;
}

@customElement('cal-news-select')
export class CalNewsSelectComponent extends LitElement {
  private responsiveController = new ResponsiveController(this);

  private options: SelectOption[] = [];

  @state()
  private currentOption: SelectOption;

  @property({
    attribute: 'horizontal',
    reflect: true,
    type: Boolean,
  })
  hasHorizontalLayout = false;

  @property({
    attribute: 'always-open',
    reflect: true,
    type: Boolean,
  })
  hasAlwaysOpenLayout = false;

  @property({
    attribute: 'expanded',
    reflect: true,
    type: Boolean,
  })
  isExpanded = false;

  @property({
    reflect: true,
    type: String,
  })
  name = '';

  @property({
    reflect: true,
    type: String,
  })
  value = '';

  @query('.cal-news-select__expandable')
  expandableContainer!: HTMLElement;

  @query('.cal-news-select__options')
  optionsContainer!: HTMLElement;

  @queryAssignedElements({
    selector: 'cal-news-select-option',
  })
  optionElements!: CalNewsSelectOptionComponent[];

  connectedCallback() {
    super.connectedCallback();

    this.responsiveController.onTargetChange(() => {
      this.collapse();
    });
  }

  protected firstUpdated(changedProperties: PropertyValues<this>) {
    super.firstUpdated(changedProperties);

    if (this.optionElements.length > 0) {
      this.options = this.optionElements.map((element) => ({
        element,
      }));

      this.currentOption = this.options[0];

      for (const option of this.options) {
        if (option.element.isSelected) {
          this.currentOption = option;
        }
      }

      // ensure that the current option actually is selected
      this.currentOption.element.isSelected = true;
      this.value = this.currentOption.element.value;
    }
  }

  private toggleExpanded() {
    if (this.isExpanded) {
      this.collapse();
    } else {
      this.expand();
    }
  }

  private expand() {
    this.isExpanded = true;
    this.expandableContainer.style.height = `${this.optionsContainer.clientHeight}px`;
  }

  private collapse() {
    this.isExpanded = false;
    this.expandableContainer.style.height = '';
  }

  private select(option: SelectOption) {
    this.currentOption.element.isSelected = false;
    this.currentOption = option;
    this.currentOption.element.isSelected = true;
    const previousValue = this.value;
    this.value = this.currentOption.element.value;

    this.dispatchEvent(
      new CustomEvent<NewsSelectChangeEvent>('onNewsSelectChange', {
        bubbles: true,
        cancelable: false,
        detail: {
          name: this.name,
          value: this.value,
          previousValue,
        },
      })
    );

    this.collapse();
  }

  protected render(): TemplateResult {
    return html`
      <slot></slot>

      <div class=${classMap({
        'cal-news-select': true,
        'cal-news-select--single-option': this.options.length === 1,
      })}
      >
        <button class="cal-news-select__button"
                @click=${this.toggleExpanded.bind(this)}
        >
          <span class="cal-news-select__current-value">
            ${this.currentOption?.element.innerText}
          </span>

          ${this.renderArrow('cal-news-select__arrow')}
        </button>

        <div class="cal-news-select__expandable">
          <div class="cal-news-select__options">
            ${map(this.options, this.renderOption.bind(this))}
          </div>
        </div>

        <div class="cal-news-select__border">
          <div class="cal-news-select__border-start"></div>
          ${this.renderArrow('cal-news-select__border-arrow')}
          <div class="cal-news-select__border-end"></div>
        </div>
      </div>
    `;
  }

  private renderArrow(cssClass: string): TemplateResult {
    return html`
      <svg class="${cssClass}"
           viewBox="0 0 30 18"
      >
        <path
            d="M0.269,1.682L15,17.466L29.731,1.682L28.269,0.318L15,14.534C15,14.534 1.731,0.318 1.731,0.318L0.269,1.682Z"
        />
      </svg>
    `;
  }

  private renderOption(option: SelectOption): TemplateResult {
    return html`
      <button class=${classMap({
        'cal-news-select__option': true,
        'cal-news-select__option--selected': option.element.isSelected,
      })}
              tabindex=${this.isExpanded ? 0 : -1}
              @click=${this.select.bind(this, option)}
      >
        ${option.element.innerText}
      </button>
    `;
  }

  static styles = [
    baseReset,
    css`
      ::slotted(*) {
        display: none;
      }

      .cal-news-select {
        /*border-bottom: 1px solid var(--cal-news-select-border-color, #000000);*/
      }

      .cal-news-select__button {
        width: 100%;
        background: transparent;
        border: 0;
        text-align: left;
        font-family: inherit;
        display: flex;
        align-items: flex-end;
        cursor: pointer;
        padding-top: 20px;
        padding-bottom: 20px;
      }

      .cal-news-select--single-option .cal-news-select__button {
        cursor: default;
      }


      .cal-news-select__current-value {
        font-size: 24px;
        line-height: 0.8;
        color: var(--cal-news-select-value-color, #000000);
        flex-grow: 1;
      }

      .cal-news-select__arrow {
        height: 15px;
        transition: fill ease-out .2s;
        fill: var(--cal-news-select-arrow-color, #000000);
      }

      :host([expanded]) .cal-news-select__arrow {
        fill: var(--cal-news-select-arrow-color-expanded, #000000);
      }

      .cal-news-select--single-option .cal-news-select__arrow,
      :host([always-open]) .cal-news-select__arrow {
        display: none;
      }

      .cal-news-select__expandable {
        height: 0;
        overflow: hidden;
        transition: height ease-out .2s;
      }

      .cal-news-select__options {
        opacity: 0;
        transform: translateY(-10px);
        transition: opacity ease-out .2s;
      }

      :host([expanded]) .cal-news-select__options {
        opacity: 1;
      }

      .cal-news-select__option {
        position: relative;
        font-family: inherit;
        cursor: pointer;
        width: 100%;
        font-size: 24px;
        line-height: 0.8;
        color: var(--cal-news-select-option-color, #000000);
        user-select: none;
        background-color: transparent;
        border: 0;
        padding-top: 10px;
        padding-bottom: 10px;
        text-align: left;
      }

      :host([always-open]) .cal-news-select__option:last-child::after {
        display: none;
      }

      :host([always-open]) .cal-news-select__option::after {
        content: '';
        display: block;
        position: absolute;
        top: 100%;
        left: 0;
        width: 80px;
        height: 1px;
        background-color: var(--cal-news-select-option-divider-color, #000000);
      }

      .cal-news-select__option--selected {
        display: none;
      }

      :host([always-open]) .cal-news-select__option--selected {
        display: block;
        --cal-news-select-option-color: var(--cal-news-select-value-color, #C00000);
      }

      .cal-news-select__border {
        display: flex;
        width: 100%;
        align-items: flex-start;
      }

      .cal-news-select__border-start {
        border-top: 1px solid var(--cal-news-select-border-color, #000000);
        width: 24px;
      }

      .cal-news-select__border-arrow {
        display: none;
        margin-left: -1px;
        margin-right: -1px;
        width: 18px;
        height: 11px;
        fill: var(--cal-news-select-border-color, #000000);
      }

      .cal-news-select__border-end {
        border-top: 1px solid var(--cal-news-select-border-color, #000000);
        flex-grow: 1;
      }
    `,
    MediaQuery.range(
      ResponsiveTargets.xs,
      ResponsiveTargets.sm
    )(css`
      :host([always-open]) .cal-news-select__button {
        display: none;
      }
      
      :host([always-open]) .cal-news-select__expandable {
        height: auto;
        padding-top: 20px;
      }
      
      :host([always-open]) .cal-news-select__options {
        opacity: 1;
      }
      
      :host([always-open]) .cal-news-select__option {
        padding-top: 0;
        padding-bottom: 16px;
        margin-bottom: 16px;
      }

      :host([always-open]) .cal-news-select__option:first-child {
        padding-top: 10px;
      }

      :host([always-open]) .cal-news-select__option:last-child {
        padding-top: 0;
        padding-bottom: 10px;
        margin-bottom: 0;
      }
    `),
    MediaQuery.range(
      ResponsiveTargets.md,
      ResponsiveTargets.xl
    )(css`
      .cal-news-select__button {
        padding-bottom: 32px;
      }

      .cal-news-select__current-value {
        flex-grow: 0;
        margin-right: 42px;
        font-size: 30px;
      }

      .cal-news-select__arrow {
        height: 18px;
      }

      .cal-news-select__options {
        transform: translateY(-16px);
      }

      .cal-news-select__option {
        font-size: 30px;
        padding-top: 16px;
        padding-bottom: 16px;
      }

      .cal-news-select__border-arrow {
        display: block;
      }

      :host([horizontal]) .cal-news-select__button,
      :host([always-open]) .cal-news-select__button {
        display: none;
      }

      :host([horizontal]) .cal-news-select__expandable,
      :host([always-open]) .cal-news-select__expandable {
        height: auto;
      }

      :host([horizontal]) .cal-news-select__options,
      :host([always-open]) .cal-news-select__options {
        opacity: 1;
        transform: none;
        display: flex;
      }

      :host([horizontal]) .cal-news-select__option,
      :host([always-open]) .cal-news-select__option {
        margin-right: 72px;
        padding-top: 20px;
        padding-bottom: 32px;
        width: auto;
      }

      :host([always-open]) .cal-news-select__option {
        padding-right: 32px;
        margin-right: 32px;
        position: relative;
      }

      :host([always-open]) .cal-news-select__option::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 100%;
        height: 36px;
        width: 1px;
        background-color: var(--cal-news-select-option-divider-color, #000000);
        transform: translateY(calc(-50% - 6px));
      }

      :host([horizontal]) .cal-news-select__option:last-child {
        margin-right: 0;
        padding-right: 0;
      }

      :host([horizontal]) .cal-news-select__option--selected {
        display: block;
        --cal-news-select-option-color: var(--cal-news-select-value-color, #C00000);
      }
    `),
  ];
}

declare global {
  interface HTMLElementTagNameMap {
    'cal-news-select': CalNewsSelectComponent;
  }
}
