import { css, html, LitElement, PropertyValues, TemplateResult } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { ParallaxController } from './parallax-controller';

@customElement('cal-parallax-image')
export class CalParallaxImageComponent extends LitElement {
  private parallaxController = new ParallaxController(this);

  @property({
    attribute: 'active',
    reflect: true,
    type: Boolean,
  })
  isActive = true;

  @property({
    attribute: 'image-url',
    reflect: true,
    type: String,
  })
  imageUrl = '';

  @property({
    attribute: 'min-offset',
    type: Number,
  })
  minOffset = -Infinity;

  @property({
    attribute: 'max-offset',
    type: Number,
  })
  maxOffset = Infinity;

  @property({
    attribute: 'limit-offset',
    reflect: true,
    type: Boolean,
  })
  limitOffset = false;

  @property({
    attribute: 'speed-modifier',
    reflect: true,
    type: Number,
  })
  speedModifier = 1;

  @query('.container')
  containerElement: HTMLElement;

  @query('.image')
  imageElement: HTMLElement;

  protected firstUpdated(changedProperties: PropertyValues<this>) {
    super.firstUpdated(changedProperties);
    this.parallaxController.firstUpdated();
  }

  protected willUpdate(changedProperties: PropertyValues<this>) {
    super.willUpdate(changedProperties);

    if (changedProperties.has('isActive')) {
      this.parallaxController.isActive = this.isActive;
    }
  }

  protected render(): TemplateResult {
    return html`
      <div class="container">
        <div class="image"
             style="background-image: url('${this.imageUrl}')"
        >
          <slot></slot>
        </div>
      </div>
    `;
  }

  static styles = [
    css`
      :host {
        display: block;
        width: 100%;
        height: 100%;
      }
      
      .container {
        width: 100%;
        height: 100%;
      }
      
      .image {
        width: 100%;
        height: 100%;
        background-position-x: var(--cal-parallax-image-focus-x, center);
        background-position-y: var(--cal-parallax-image-focus-y, center);
        background-size: var(--cal-parallax-image-background-size, cover);
        background-repeat: no-repeat;
        will-change: transform;
      }
    `,
  ];
}

declare global {
  interface HTMLElementTagNameMap {
    'cal-parallax-image': CalParallaxImageComponent;
  }
}
