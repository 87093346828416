import { css, html, LitElement, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { baseReset } from '../../../libs/styles/reset';

@customElement('cal-icon')
export class CalIconComponent extends LitElement {
  protected render(): TemplateResult {
    return html`
      <span class="icon">
        <slot></slot>
      </span>
    `;
  }

  static styles = [
    baseReset,
    css`
      :host {
        display: inline;
      }
      
      .icon {
        font-family: var(--font-icon, serif), serif;
        font-size: var(--cal-icon-font-size, inherit);
        color: var(--cal-icon-color, inherit);
        display: block;
      }
    `,
  ];
}

declare global {
  interface HTMLElementTagNameMap {
    'cal-icon': CalIconComponent;
  }
}
