import { css, LitElement } from 'lit';
import { property } from 'lit/decorators.js';
import { MediaQuery, ResponsiveTargets } from '../../styles/responsive';
import { baseReset } from '../../../libs/styles/reset';

export class HeroSlide extends LitElement {
  @property({
    attribute: 'active',
    reflect: true,
    type: Boolean,
  })
  isActive = false;

  @property({
    attribute: 'standalone',
    reflect: true,
    type: Boolean,
  })
  isStandalone = false;

  onActivate(): void {}

  onDeactivate(): void {}

  get height(): number {
    return this.clientHeight;
  }

  static slideStyles = [
    baseReset,
    css`
      :host {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        opacity: 0;
        transition: opacity ease-out .4s;
        background-color: var(--cal-hero-slide-background-color, #FFFFFF);
      }
      
      :host([active]) {
        position: relative;
        z-index: 10;
        opacity: 1;
      }
    `,
  ];
}
