import anime from 'animejs';
import {
  NumberParser,
  NumberParsingResult,
} from '../../../libs/parsing/number-parser';
import { EventBus } from '../../../libs/events';

export type CountAnimationEventTypes = 'start' | 'update' | 'complete';
export interface CountAnimationUpdateEvent {
  number: string;
  parsedNumber: NumberParsingResult;
}

export class CountAnimation {
  private initialValue = '';
  private parsedNumber: NumberParsingResult;

  private numberAnimationObject = {
    number: 0,
  };

  private _isAnimating = false;
  private _isComplete = false;

  readonly events = new EventBus<CountAnimationEventTypes>();

  prepareAnimation(initialValue: string) {
    this.initialValue = initialValue;
    this.parsedNumber = NumberParser.parse(this.initialValue);
  }

  doAnimation(): Promise<unknown> {
    this._isAnimating = true;

    if (isNaN(this.parsedNumber.number)) {
      this.events.dispatch('complete', null);
      return Promise.resolve();
    }

    this.events.dispatch('start', null);

    return anime({
      targets: this.numberAnimationObject,
      number: this.parsedNumber.number,
      duration: 2000,
      delay: 400,
      easing: 'easeOutQuint',
      update: () => {
        const number = this.formatNumber(this.numberAnimationObject.number);
        this.events.dispatch<CountAnimationUpdateEvent>('update', {
          number,
          parsedNumber: this.parsedNumber,
        });
      },
      complete: () => {
        this._isAnimating = false;
        this._isComplete = true;
        this.events.dispatch('complete', null);
      },
    }).finished;
  }

  private formatNumber(number: number): string {
    const locale = undefined; // this means the locale of the users browser is taken
    return number.toLocaleString(locale, {
      maximumFractionDigits: this.parsedNumber.fraction.length,
      minimumFractionDigits: Math.min(this.parsedNumber.fraction.length, 1),
    });
  }

  get isAnimating(): boolean {
    return this._isAnimating;
  }

  get isComplete(): boolean {
    return this._isComplete;
  }
}
