import { css, html, LitElement, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { baseReset } from '../../../libs/styles/reset';

@customElement('cal-teaser-text')
export class CalTeaserTextComponent extends LitElement {
  protected render(): TemplateResult {
    return html`
      <slot></slot>
    `;
  }

  static styles = [
    baseReset,
    css`
      :host {
        color: var(--cal-teaser-text-color, #000000);
        font-size: var(--cal-teaser-text-size, 22px);
        line-height: var(--cal-teaser-text-line-height, 1.23);
        font-weight: var(--cal-teaser-text-weight, bold);
      }
    `,
  ];
}

declare global {
  interface HTMLElementTagNameMap {
    'cal-teaser-text': CalTeaserTextComponent;
  }
}
