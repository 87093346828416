import { LitElement, TemplateResult, html, css } from 'lit';
import { customElement } from 'lit/decorators.js';
import { baseReset } from '../../../../libs/styles/reset';
import { visuallyHiddenMixin } from '../../../../libs/styles/mixins';

@customElement('cal-logo')
export class CalLogoComponent extends LitElement {
  protected render(): TemplateResult {
    return html`
      <figure class="cal-logo">
        <svg viewBox="0 0 166 17"
             xmlns="http://www.w3.org/2000/svg"
             class="cal-logo__svg"
        >
          <g>
            <path
                d="M90.5646 9.45662H95.4806V13.2284C94.2929 14.2771 92.7735 14.8502 91.2041 14.8414C86.9275 14.8414 84.9388 11.7892 84.9388 8.55715C84.9388 5.02522 87.1035 1.96102 91.1454 1.96102C92.8592 1.97888 94.5067 2.64053 95.774 3.81993L96.8827 2.50071C95.3566 0.955927 93.2932 0.0932714 91.1454 0.102118C86.0534 0.102118 83.0088 3.75397 83.0088 8.53916C83.0088 12.8506 85.5841 16.6824 91.1454 16.6824C92.282 16.7073 93.4103 16.4789 94.4518 16.0129C95.4933 15.5469 96.4229 14.8545 97.176 13.984V7.72364H90.5646V9.45662Z"
            />
            <path
                d="M113.872 5.5645C113.872 2.99201 112.112 0.479492 108.592 0.479492H101.517V16.4601H103.447V10.7095H108.252L112.581 16.5201H115.045L110.416 10.4156C112.874 9.75603 113.872 7.71723 113.872 5.5645ZM103.388 8.91653V2.27244H108.545C108.987 2.24449 109.429 2.31139 109.844 2.46883C110.258 2.62626 110.636 2.87074 110.953 3.18652C111.27 3.5023 111.518 3.88238 111.682 4.30227C111.846 4.72217 111.923 5.17257 111.906 5.62446C111.906 7.42341 110.792 8.91653 108.451 8.91653H103.388Z"
            />
            <path
                d="M147.421 10.0559C147.421 13.168 145.433 14.901 142.975 14.901C140.517 14.901 138.463 13.228 138.463 10.0559V0.479492H136.545V10.0738C136.545 14.5592 139.648 16.7179 142.998 16.7179C146.348 16.7179 149.316 14.4992 149.316 10.0738V0.479492H147.445L147.421 10.0559Z"
            />
            <path
                d="M125.112 0.179688C120.196 0.179688 117.151 3.83154 117.151 8.4968C117.151 12.6943 119.726 16.7539 125.112 16.7539C130.497 16.7539 133.025 12.6883 133.025 8.4968C133.025 4.30526 130.38 0.239652 125.112 0.179688ZM125.112 14.901C121.005 14.901 119.081 11.7289 119.081 8.4968C119.081 5.02484 121.134 2.0326 125.112 2.0326C129.218 2.09256 131.083 5.2647 131.083 8.4968C131.083 11.7289 129.212 14.901 125.112 14.901Z"
            />
            <path
                d="M160.597 0.479492H153.51V16.4601H155.446V11.3091H160.656C167.801 11.3091 167.801 0.479492 160.597 0.479492ZM160.597 9.4742H155.388V2.3324H160.597C165.22 2.3324 165.22 9.45621 160.597 9.45621V9.4742Z"
            />
            <path
                d="M64.5709 5.08516C64.0465 3.96601 63.2776 2.98495 62.3236 2.21766C61.3695 1.45037 60.2558 0.917366 59.0682 0.659763C57.824 0.514339 56.5717 0.45424 55.3196 0.479869H52.3865V16.5804H57.4785C58.7192 16.575 59.9417 16.2748 61.0494 15.7035C62.1571 15.1322 63.1197 14.3053 63.861 13.2883C64.6707 12.1038 65.1624 10.723 65.2869 9.28358C65.4115 7.84418 65.1646 6.39656 64.5709 5.08516ZM61.2329 10.2901C60.9153 10.9954 60.4139 11.5978 59.7838 12.0314C59.1537 12.465 58.4191 12.7131 57.6603 12.7487H56.3111V4.35359H56.3697C56.6604 4.30381 56.9551 4.28372 57.2497 4.29363C57.966 4.27099 58.6768 4.42803 59.3201 4.75103C59.9633 5.07403 60.5193 5.55316 60.9396 6.14653C61.3189 6.75893 61.5442 7.45772 61.5954 8.18066C61.6466 8.9036 61.5221 9.62826 61.2329 10.2901Z"
            />
            <path
                d="M6.54106 4.19737C7.20508 3.89017 7.92414 3.72684 8.65295 3.71765C9.6869 3.69631 10.6976 4.03225 11.5216 4.67109L13.8036 2.09861C13.3964 1.7062 12.9455 1.36406 12.4602 1.07921L12.3429 1.01924C11.1053 0.325611 9.71211 -0.0257746 8.30097 -0.000158031C6.86405 -0.00789539 5.44955 0.363903 4.19452 1.07921C2.71137 1.95482 1.5241 3.27118 0.790575 4.85325C0.0570507 6.43531 -0.187973 8.20813 0.0880818 9.936C0.370627 11.4445 1.03413 12.8513 2.01275 14.0167C2.99137 15.1822 4.25111 16.0659 5.66698 16.5801C6.49547 16.8701 7.36665 17.0121 8.2423 16.9998C10.2102 17.0141 12.119 16.3127 13.6276 15.021L11.8149 12.0228C10.9231 12.7673 9.81016 13.1796 8.65881 13.1921C7.7496 13.203 6.85553 12.9537 6.07762 12.4725C5.29698 12.0143 4.66784 11.3285 4.27071 10.5027C3.87359 9.67699 3.72655 8.74889 3.84841 7.83723C3.97461 7.05273 4.2857 6.31141 4.75482 5.67727C5.22394 5.04313 5.837 4.53521 6.54106 4.19737Z"
            />
            <path d="M48.5263 0.479492H44.6604V16.5201H48.5263V0.479492Z"
            />
            <path d="M36.4008 12.8682V12.8082V0.479492H32.5935V16.5201H42.0266V12.8682H36.5181H36.4008Z"
            />
            <path
                d="M20.9779 0.479492L14.6833 16.5201H18.7898L19.6639 14.4813V14.4213H25.5713V14.4813L25.9233 15.3807C26.0342 15.7698 26.1714 16.1505 26.3339 16.5201H30.3172L24.2866 0.479492H20.9779ZM24.3159 11.429H20.714L20.7726 11.3091L22.468 6.46397L22.5267 6.22411L22.5853 6.46397C22.5853 6.52394 23.7586 9.69607 24.2866 11.3091L24.3452 11.429H24.3159Z"
            />
            <path
                d="M71.1881 0.479492L64.9229 16.5201H69.0293L69.9092 14.4813V14.4213H75.8225V14.4813C75.9399 14.7811 76.0572 15.0809 76.1745 15.3208C76.2952 15.7342 76.4523 16.1356 76.6438 16.5201H80.6212L74.5261 0.479492H71.1881ZM74.6434 11.429H70.9535L71.0121 11.3091L72.7075 6.46397L72.7662 6.22411L72.8248 6.46397L74.5847 11.3091L74.6434 11.429Z"
            />
          </g>
        </svg>
        
        <figcaption class="cal-logo__caption">
          Calida Group Logo
        </figcaption>
      </figure>
    `;
  }

  static styles = [
    baseReset,
    css`
      .cal-logo {
        height: 100%;
      }
      
      .cal-logo__svg {
        height: 100%;
        fill: var(--cal-logo-color, #000000);
        transition: fill ease-out .2s;
      }
      
      .cal-logo__caption {
        ${visuallyHiddenMixin}
      }
    `,
  ];
}

declare global {
  interface HTMLElementTagNameMap {
    'cal-logo': CalLogoComponent;
  }
}
