import { ViewController } from '../../../libs/view-controllers/view-controller';
import { PageLoadService } from '../../utils/services/page-load-service';
import { ServiceLocator } from '../../../libs/services';
import { Service } from '../../services';

export class ImageController extends ViewController<HTMLImageElement> {
  private pageLoadService: PageLoadService;

  getDependencies() {
    this.pageLoadService = ServiceLocator.get(Service.PageLoad);
  }

  onInit() {
    this.pageLoadService.isLoaded.subscribe((isLoaded) => {
      if (isLoaded) {
        this.host.classList.add('loaded');
      }
    });
  }
}

ImageController.attachTo('img');
