import { invLerp } from './inv-lerp';
import { lerp } from './lerp';

export function remap(
  fromMin: number,
  fromMax: number,
  value: number,
  toMin: number,
  toMax: number
): number {
  const t = invLerp(fromMin, value, fromMax);
  return lerp(toMin, t, toMax);
}

export function roundedRemap(
  fromMin: number,
  fromMax: number,
  value: number,
  toMin: number,
  toMax: number
): number {
  return Math.round(remap(fromMin, fromMax, value, toMin, toMax));
}
