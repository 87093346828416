import anime, { AnimeInstance } from 'animejs';
import { PageLoadService } from '../../utils/services/page-load-service';
import { NavigationOverlayService } from './navigation-overlay';

export class AnchorScrollController {
  private host: HTMLElement;
  private pageLoadService: PageLoadService;
  private navigationOverlayService: NavigationOverlayService;

  private currentTarget: HTMLElement | null = null;
  private currentAnimation: AnimeInstance | null = null;

  constructor(
    host: HTMLElement,
    pageLoadService: PageLoadService,
    navigationOverlayService: NavigationOverlayService
  ) {
    this.host = host;
    this.pageLoadService = pageLoadService;
    this.navigationOverlayService = navigationOverlayService;
    this.registerListeners();
  }

  private registerListeners() {
    window.addEventListener('wheel', this.wheelListener);
    window.addEventListener('touchmove', this.wheelListener);
    window.addEventListener('hashchange', this.hashChangeListener);
    this.pageLoadService.isLoaded.subscribe(this.onPageLoad);

    this.host.addEventListener(
      'onNavigationItemClick',
      (event: CustomEvent<MouseEvent>) => {
        this.scrollToTarget();
      }
    );
  }

  private wheelListener = () => {
    // cancel animation when user scrolls
    this.currentAnimation?.pause();
    anime.remove(this.currentTarget);
  };

  private hashChangeListener = () => {
    this.scrollToTarget();
  };

  private onPageLoad = (isLoaded: boolean) => {
    if (!isLoaded) {
      return;
    }
    this.scrollToTarget();
  };

  private scrollToTarget() {
    this.navigationOverlayService.close();
    this.doScrollAnimationTo(this.getTargetElement());
  }

  private doScrollAnimationTo(element: HTMLElement) {
    const rect = element.getBoundingClientRect();
    const targetY = window.scrollY + rect.top;

    const scrollObject = {
      y: window.scrollY,
    };

    this.currentAnimation = anime({
      targets: scrollObject,
      y: targetY,
      easing: 'easeOutExpo',
      update: () => {
        window.scroll(0, scrollObject.y);
      },
      complete: () => {
        this.currentAnimation = null;
      },
    });
  }

  private getTargetElement(): HTMLElement {
    try {
      this.currentTarget = document.querySelector<HTMLElement>(
        window.location.hash
      );
    } catch (error) {
      this.currentTarget = document.body;
    }
    return this.currentTarget;
  }
}
