import { css, CSSResult } from 'lit';

export type ResponsiveTargetKey =
  | 'xs'
  | 'sm'
  | 'md'
  | 'md_landscape'
  | 'lg'
  | 'xl';

export interface ResponsiveTarget {
  key: ResponsiveTargetKey;
  minWidth: number;
  maxWidth: number;
}

export const ResponsiveTargets: Record<ResponsiveTargetKey, ResponsiveTarget> =
  {
    xs: {
      key: 'xs',
      minWidth: 0,
      maxWidth: 359,
    },
    sm: {
      key: 'sm',
      minWidth: 360,
      maxWidth: 767,
    },
    md: {
      key: 'md',
      minWidth: 768,
      maxWidth: 1023,
    },
    md_landscape: {
      key: 'md_landscape',
      minWidth: 1024,
      maxWidth: 1279,
    },
    lg: {
      key: 'lg',
      minWidth: 1280,
      maxWidth: 1439,
    },
    xl: {
      key: 'xl',
      minWidth: 1440,
      maxWidth: Infinity,
    },
  };

export class MediaQuery {
  static range(
    from: ResponsiveTarget,
    to?: ResponsiveTarget
  ): (content: CSSResult) => CSSResult {
    const minWidth = from.minWidth;
    const maxWidth = to?.maxWidth || from.maxWidth;

    if (maxWidth < Infinity) {
      return (content: CSSResult) => css`
        @media screen and (min-width: ${minWidth}px) and (max-width: ${maxWidth}px) {
          ${content}
        }
      `;
    } else {
      return (content: CSSResult) => css`
        @media screen and (min-width: ${minWidth}px) {
          ${content}
        }
      `;
    }
  }

  static isActive(from: ResponsiveTarget, to?: ResponsiveTarget): boolean {
    const minWidth = from.minWidth;
    const maxWidth = to?.maxWidth || from.maxWidth;

    const currentWindowWidth = window.innerWidth;

    if (maxWidth < Infinity) {
      return currentWindowWidth >= minWidth && currentWindowWidth <= maxWidth;
    } else {
      return currentWindowWidth >= minWidth;
    }
  }
}
