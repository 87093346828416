import { css, html, LitElement, PropertyValues, TemplateResult } from 'lit';
import { customElement, queryAssignedNodes } from 'lit/decorators.js';
import { countAnimation } from '../../utils/directives/count-animation-directive';

@customElement('cal-animated-number')
export class CalAnimatedNumberComponent extends LitElement {
  private initialValue = '';
  private displayValue = '';

  @queryAssignedNodes()
  nodes!: Node[];

  protected firstUpdated(changedProperties: PropertyValues<this>) {
    super.firstUpdated(changedProperties);

    const contents: string[] = [];
    for (const node of this.nodes) {
      if (node.nodeType === Node.TEXT_NODE) {
        contents.push(node.textContent.trim());
      }
    }

    this.initialValue = contents.join('');
    this.displayValue = this.initialValue;
    this.requestUpdate();
  }

  protected render(): TemplateResult {
    return html`
      <span class="initial-value">
        <slot></slot>
      </span>
      <span class="display-value" ${countAnimation(this.displayValue)}>
      </span>
    `;
  }

  static styles = [
    css`
      .initial-value {
        display: none;
      }
      
      .display-value {
        display: inline-block;
      }
    `,
  ];
}

declare global {
  interface HTMLElementTagNameMap {
    'cal-animated-number': CalAnimatedNumberComponent;
  }
}
