import { css, html, LitElement, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { baseReset } from '../../../libs/styles/reset';

@customElement('cal-news-select-option')
export class CalNewsSelectOptionComponent extends LitElement {
  @property({
    reflect: true,
    type: String,
  })
  value = '';

  @property({
    attribute: 'selected',
    reflect: true,
    type: Boolean,
  })
  isSelected = false;

  protected render(): TemplateResult {
    return html`
      <slot></slot>
    `;
  }

  static styles = [
    baseReset,
    css`
    `,
  ];
}

declare global {
  interface HTMLElementTagNameMap {
    'cal-news-select-option': CalNewsSelectOptionComponent;
  }
}
